import {field} from "../misc/StoreField";
import {apiClient} from "../../connection/BlinkApiConnection";
import {MakeStore, StoreObject} from "../../../stem-core/src/state/Store";


class MerchantEmailSender extends StoreObject {
    @field("Merchant") merchant;
    @field(String) emailAddress;
    @field(String) name;

    toString() {
        return this.emailAddress;
    }
}

export const MerchantEmailSenderStore = MakeStore("MerchantEmailSender", MerchantEmailSender)

export async function apiMerchantCreateEmailSender(request) {
    return apiClient.post("merchant/create_email_sender/", request);
}

export async function apiMerchantUpdateEmailSender(request) {
    return apiClient.post("merchant/update_email_sender/", request);
}

export async function apiMerchantDeleteEmailSender(request) {
    return apiClient.post("merchant/delete_email_sender/", request);
}
