import {MakeStore, StoreObject} from "../../../stem-core/src/state/Store";
import {field} from "../misc/StoreField";
import {apiConnection} from "../../connection/BlinkApiConnection";
import {MerchantUserStore} from "./MerchantUserStore";


class MerchantStaff extends StoreObject {
    @field("Merchant") merchant;
    @field("UserProfile") user;
    @field(Date) createdAt;
    // @field(Date) lastModifiedAt; // TODO add in backend and her
    @field("UserProfile") createdBy;
    @field(Boolean) isOwner;
    @field(Boolean) hasEditPermissions;

    getMerchantUser() {
        return MerchantUserStore.getByUserAndMerchant(this.userId, this.merchantId);
    }

    getEmail() {
        return this.getMerchantUser().email;
    }
}

export const MerchantStaffStore = MakeStore("MerchantStaff", MerchantStaff, {dependencies: ["MerchantUser"]});

export async function apiMerchantCreateStaffMember(request) {
    return apiConnection.post("/merchant/create_staff_member/", request);
}

export async function apiMerchantEditStaffMember(request) {
    return apiConnection.post("/merchant/edit_staff_member", request);
}

export async function apiMerchantDeleteStaffMember(request) {
    return apiConnection.post("/merchant/delete_staff_member/", request);
}