import {UI} from "../../stem-core/src/ui/UIBase";
import {styleRule, styleRuleInherit, StyleSheet} from "../../stem-core/src/ui/Style";
import {InfoIcon} from "../SVGElements";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";
import {unwrapArray} from "../../stem-core/src/base/Utils";
import {DashboardAnchoredPopup, DashboardAnchoredPopupStyle} from "../ui/input/PopupInput";
import {isFunction} from "../../stem-core/src/base/Utils";

export class InfoElementStyleSheet extends StyleSheet {
    @styleRule
    infoAnchor = {
        display: "inline-block",
        cursor: "help",
    }
}

class InfoPopupStyle extends DashboardAnchoredPopupStyle {
    @styleRuleInherit
    popup = {
        maxWidth: 360,
        maxHeight: 480,
        fontSize: 15,
        pointerEvents: "none",
    }
}

@registerStyle(InfoPopupStyle)
export class InfoContentPopup extends DashboardAnchoredPopup {
    getDefaultOptions(options) {
        return {
            ...super.getDefaultOptions(options),
            toggleOnSameAnchor: true,
            hideOnMouseLeave: true,
        };
    }
}

@registerStyle(InfoElementStyleSheet)
class InfoElement extends UI.Primitive("span") {
    extraNodeAttributes(attr) {
        attr.addClass(this.styleSheet.infoAnchor);
    }

    render() {
        return <InfoIcon/>;
    }

    showPopup() {
        let {content} = this.options;
        InfoContentPopup.show({
            anchor: this,
            content,
        });
    }

    onMount() {
        for (const eventType of ["mouseenter", "tap"]) {
            this.addNodeListener(eventType, () => this.showPopup());
        }
    }

    static optionally(content) {
        const Class = this;
        return content ? <Class content={content} /> : null;
    }
}

// TODO @Mihai see why this fails if content is a UIElement
export function MakeInfoElement(content) {
    return InfoElement.optionally(content);
}
