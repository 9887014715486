import {UI} from "../../../stem-core/src/ui/UIBase";
import {
    apiRenderEmailTemplate,
    apiSendTestEmailTemplate,
    apiUpdateEmailTemplate, EmailTemplateType,
} from "../../../client/state/EmailTemplateStore";
import {Button} from "../../../stem-core/src/ui/button/Button";
import {TextInput} from "../../../stem-core/src/ui/input/Input";
import {BlinkInputField} from "../../common/Input";
import {CodeEditor} from "../../../stem-core/src/ui/CodeEditor";
import {StemDate} from "../../../stem-core/src/time/Date";
import {Panel, RawHTML} from "../../../stem-core/src/ui/UIPrimitives";
import {FlatTabArea} from "../../../stem-core/src/ui/tabs/FlatTabArea";
import {Select} from "../../../stem-core/src/ui/input/Input";
import {Level} from "../../../stem-core/src/ui/Constants";
import {DashboardTitle} from "../../common/DashboardTitle";
import {MerchantEmailSenderStore} from "../../../client/state/merchant/MerchantEmailSender";
import {MerchantEmailingSettingsStore} from "../../../client/state/merchant/MerchantEmailingSettingsStore";
import {MultiEmailAddressInput} from "../../common/Input";
import {CycleUnit} from "../../../client/state/RecurringPaymentStoreObject";
import {CurrencyStore} from "../../../client/state/CurrencyStore";
import {DashboardMoneyInput} from "../../ui/input/DashboardMoneyInput";
import {LabeledCheckbox} from "../../../blinkpay/ui/Checkbox";


function uiRef(targetProto, name, descriptor) {
    const rawField = Symbol.for("ref-" + name);

    return {
        get() {
            return this[rawField] || {
                parent: this,
                name: name,
            }
        },
        set(value) {
            this[rawField] = value;
        }
    }
}


class EmailTemplatePreviewPanel extends Panel {
    addShowListener(callback) {
        return this.addListener("show", () => callback(this));
    }

    // TODO Try a context pattern here
    redrawPreview() {
        this.options.parentEditor.previewEmailTemplate(this);
    }

    renderSuccessfulDonationContextInputs() {
        const {emailTemplate} = this.options;
        const merchant = emailTemplate.merchant;
        const currency = merchant?.getCurrency() || CurrencyStore.get("USD");
        return [
            // TODO @branch make this DashboardMoneyInput
            <BlinkInputField label="Amount">
                <DashboardMoneyInput
                    ref="fieldPreviewAmount"
                    currency={currency}
                    onChange={() => this.redrawPreview()}
                    min={"1"} max={"1000"}
                    value={"20"}
                />
            </BlinkInputField>,
            <BlinkInputField label="Frequency">
                <Select
                    ref="fieldFrequency"
                    options={CycleUnit.all()}
                />
            </BlinkInputField>
        ]
    }

    renderSuccessfulSubscriptionContextInputs() {
        // TODO @branch cleanup this
        return [
            <BlinkInputField label="Subscription tier">
                <Select
                    options={["Sub1", "sub2"]}
                />
            </BlinkInputField>
        ]
    }

    renderContextInputs(emailTemplate) {
        if (emailTemplate.type === EmailTemplateType.DONATION_SUCCESS) {
            return this.renderSuccessfulDonationContextInputs();
        }
        if (emailTemplate.type === EmailTemplateType.SUBSCRIPTION_SUCCESS) {
            return this.renderSuccessfulSubscriptionContextInputs();
        }
    }

    getExtraContext() {
        // TODO properly implement this
        const amount = this.fieldAmount?.getValue();
        return {
            amount: amount?.getAmount(),
            currencyId: amount?.getCurrency().id,
            frequency: this.fieldFrequency?.getValue(),
        }
    }

    render() {
        const {parentEditor, html, emailTemplate} = this.options;

        return [
            <div style={{marginTop: 10}}>
                <span>
                    {this.renderContextInputs(emailTemplate)}
                </span>
                <span style={{float: "right"}}>
                    <Button onClick={() => parentEditor.sendTestEmail(this.getExtraContext(), this.includeDefaultBCCInput.getValue())}>
                        Send me a test email
                    </Button>
                    <div>
                        <LabeledCheckbox initialValue={true} ref="includeDefaultBCCInput" label={"Include default BCC"}/>
                    </div>
                </span>
            </div>,
            // TODO: figure out variable height better
            <div style={{height: 600}}>
                {html && <RawHTML innerHTML={html}/>}
            </div>
        ]
    }
}

export class EmailTemplateEditor extends UI.Element {
    blink_default_sending_email = "Blink default"; // TODO: what's this snakecase nonsense?

    getEmailTemplate() {
        return this.options.emailTemplate;
    }

    getMerchant() {
        return this.getEmailTemplate().merchant;
    }

    getDefaultEmailingSettings() {
        const merchant = this.getMerchant();
        return MerchantEmailingSettingsStore.filterBy({merchantId: merchant?.id});
    }

    getEmailSenders() {
        const merchant = this.getMerchant();
        return MerchantEmailSenderStore.filterBy({merchantId: merchant?.id});
    }

    // TODO rename
    getEmailPreviewContext() {
        const merchant = this.getMerchant();

        // TODO: if merchant == null, use a default
        return {
            publisher_name: merchant?.name || "Blink Times",
            publisher_url: merchant?.url || "https://dev.blinktimes.com",
            publisher_logo_url: merchant?.wideLogoUrl || "https://cdn.blink.net/news/images/logos/blinkTimes.svg",

            user_dashboard_url: "https://blink.net",
            year: StemDate.format(Date.now(), "YYYY"),

            cycle_duration: "month",
            amount: "$20.00",
            contribution: "$20.00 / month",
            recurring: true,

            code: "434343",
            display_code: "434-343",

            // Useful for preview-ing change email confirmation/undo
            old_email: "john.doe@yahoo.com",
            new_email: "john@doe.net",
            undo_url: "https://example.com",
        }
    }

    getDefaultInputValues() {
        const defaultEmailingSettings = this.getDefaultEmailingSettings();
        const emailTemplate = this.getEmailTemplate();
        const emailSenders = this.getEmailSenders();
        const templateEmailSenderIndex = emailSenders.findIndex(emailSender => emailSender.id === emailTemplate.emailSenderId);

        return {
            fieldSendingAddress: emailSenders ? emailSenders[templateEmailSenderIndex] : null,
            replyTo: emailTemplate.replyTo || defaultEmailingSettings.emailReplyTo,
            subject: emailTemplate.subject,
            bccEmails: emailTemplate.bccEmails || defaultEmailingSettings.bccEmails,
            html: emailTemplate.html,
        }
    }

    resetInputs() {
        const defaultInputValues = this.getDefaultInputValues();

        this.fieldSendingAddress.setValue(defaultInputValues.fieldSendingAddress);
        this.fieldReplyTo.setValue(defaultInputValues.replyTo);
        this.fieldSubject.setValue(defaultInputValues.subject);
        this.fieldBccEmails.setValue(defaultInputValues.bccEmails);
        this.fieldHTMLEditor.setValue(defaultInputValues.html);

        // TODO: reset preview as well
    }

    getSelectedEmailSender() {
        return this.fieldSendingAddress?.getValue();
    }

    // TODO: consider implementing this decorator - @wrapInLoadingSpinner
    async saveEmailTemplate() {
        const emailTemplate = this.getEmailTemplate();

        const request = {
            templateId: emailTemplate.id,
            emailSenderId: this.getSelectedEmailSender()?.id,
            replyTo: this.fieldReplyTo.getValue(),
            bccEmails: this.fieldBccEmails.getValue(),
            subject: this.fieldSubject.getValue(),
            html: this.fieldHTMLEditor.getValue(),
            plainTextIsAuto: true, // TODO Only one supported for now
        }

        await apiUpdateEmailTemplate(request);
    }

    getPreviewEmailRequest() {
        return {
            subject: this.fieldSubject.getValue(),
            html: this.fieldHTMLEditor.getValue(),
            plainText: "",
            context: this.getEmailPreviewContext(),
            emailType: this.getEmailTemplate().type,
        }
    }

    async previewEmailTemplate(previewPanel) {
        const request = this.getPreviewEmailRequest();

        this.previewResponse = await apiRenderEmailTemplate(request);

        previewPanel.updateOptions({html: this.previewResponse.html});
    }

    sendTestEmail(baseContext, includeBcc) {
        const request = {
            ...this.getPreviewEmailRequest(baseContext),
            fromEmail: "support@blink.net", // TODO: wait, we can customize this as well?
            replyTo: this.fieldReplyTo.getValue(),
            bccEmails: includeBcc && this.fieldBccEmails.getValue(),
        }

        apiSendTestEmailTemplate(request);
    }

    render() {
        const defaultInputValues = this.getDefaultInputValues();
        const emailTemplate = this.getEmailTemplate();
        const senderDropdownOptions = this.getEmailSenders();

        return [
            <div style={{padding: 8}}>
                <DashboardTitle style={{display: "inline-block"}} title="Edit email template"/>
                <span style={{float: "right"}}>
                    <Button level={Level.SECONDARY} onClick={() => this.resetInputs()}>
                        Reset changes
                    </Button>
                    <Button onClick={() => this.saveEmailTemplate()}>
                        Save changes
                    </Button>
                </span>
            </div>,
            // I really hate doing this but not giving it a key, all the refs downwards get wrecked
            <FlatTabArea style={{height: 640}} key={Math.random()}>
                <Panel title="General">
                    <BlinkInputField label="Sending email address">
                        {senderDropdownOptions.length === 0 ?
                            <TextInput disabled={true} placeholder={this.blink_default_sending_email}/> :
                            <Select ref="fieldSendingAddress"
                                    options={senderDropdownOptions}
                                    selected={this.getSelectedEmailSender() || defaultInputValues.fieldSendingAddress}
                            />
                        }
                    </BlinkInputField>
                    <BlinkInputField
                        style={{display: "block"}}
                        label="Reply To"
                        helpInfo="Users will by default reply to this email address."
                    >
                        <TextInput
                            style={{width: 420}}
                            value={defaultInputValues.replyTo}
                            ref={"fieldReplyTo"}
                        />
                    </BlinkInputField>
                    <BlinkInputField
                        style={{display: "block"}}
                        label="Bcc (optional)"
                        helpInfo="A comma separated list of email that will also receive emails sent to users. Can be empty."
                    >
                        <MultiEmailAddressInput
                            style={{width: 420}}
                            value={defaultInputValues.bccEmails}
                            ref={"fieldBccEmails"}
                        />
                    </BlinkInputField>
                    <BlinkInputField
                        style={{display: "block"}}
                        label="Subject"
                    >
                        <TextInput
                            style={{width: 600}}
                            value={emailTemplate.subject}
                            ref={"fieldSubject"}
                        />
                    </BlinkInputField>
                </Panel>
                <Panel title="Edit body" style={{height: "100%"}}>
                    <CodeEditor
                        aceMode="django"
                        style={{width: "100%", height: "100%", margin: "auto"}}
                        value={emailTemplate.html}
                        ref={"fieldHTMLEditor"}
                    />
                </Panel>
                <EmailTemplatePreviewPanel
                    title="Preview"
                    emailTemplate={emailTemplate}
                    parentEditor={this}
                    onShow={(previewPanel) => this.previewEmailTemplate(previewPanel)}
                />
            </FlatTabArea>,
        ]
    }
}
