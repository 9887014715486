import {MakeStore, StoreObject} from "../../stem-core/src/state/Store";
import {field} from "./misc/StoreField";


export class Jurisdiction extends StoreObject {
    @field(String) countryCode;
    @field(String) name;
    @field("Currency") defaultCurrency;

    toString() {
        return this.name;
    }
}

export const JurisdictionStore = MakeStore("Jurisdiction", Jurisdiction);
