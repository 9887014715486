import {registerStyle, styleRule, StyleSheet, UI} from "stem-core/src/ui/UI";
import {Link} from "stem-core/src/ui/UIPrimitives";
import {LogOutIcon} from "./NavSidePanelIcons";
import {MakeIcon} from "../stem-core/src/ui/SimpleElements";


class DashboardSidePanelStyle extends StyleSheet {
    @styleRule
    navSidePanel = {
        color: this.themeProps.PAGE_TEXT_COLOR,
        background: this.themeProps.SIDEBAR_BACKGROUND,
        position: "fixed",
        width: this.themeProps.SIDEBAR_WIDTH,
        height: "100%",
        padding: "36px 0 36px 36px",
        borderRight: "1px solid #E5EAE9",
        overflow: "auto",
    };

    @styleRule
    iconContainer = {
        alignItems: "center",
        display: "inline-flex",
        width: 24,
        marginRight: 12,
    };

    @styleRule
    defaultLink = {
        textDecoration: "none",
        alignItems: "center",
        display: "flex",
        fontSize: 15,
        cursor: "pointer",
        color: this.themeProps.SIDEBAR_LINK_DEFAULT_COLOR,
    };

    @styleRule
    mainSectionLink = {
        marginTop: 16,
    };

    @styleRule
    subsectionLink = {
        paddingLeft: 48,
        marginTop: 8,
    };

    @styleRule
    activeLink = {
        color: this.themeProps.SIDEBAR_LINK_ACTIVE_COLOR,
        fontWeight: 700,
    };
}

@registerStyle(DashboardSidePanelStyle)
class NavLink extends Link {
    setOptions(options) {
        return super.setOptions({
            ...options,
            href: "/" + options.page.url,
        });
    }

    extraNodeAttributes(attr) {
        const {page} = this.options;
        const {styleSheet} = this;

        super.extraNodeAttributes(attr);

        attr.addClass(styleSheet.defaultLink);

        if (page.isMainSection) {
            attr.addClass(styleSheet.mainSectionLink);
        } else {
            attr.addClass(styleSheet.subsectionLink);
        }

        if (this.isActive()) {
            attr.addClass(this.styleSheet.activeLink);
        }
    }

    isActive() {
        return this.options.isActive;
    }

    render() {
        const {styleSheet} = this;
        const {page} = this.options;
        const {icon, name, LabelClass} = page;

        return [
            icon && <span className={styleSheet.iconContainer}>{MakeIcon(icon)}</span>,
            <span>{name}</span>,
            LabelClass && <LabelClass />,
        ];
    }
}

@registerStyle(DashboardSidePanelStyle)
export class DashboardSidePanel extends UI.Element {
    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.navSidePanel);
    }

    setURL(url) {
        this.updateOptions({url});
    }

    getChildrenToRender() {
        const {url, pages, handleLogout} = this.options;

        return [
            this.render(),

            pages.map(page => <NavLink isActive={url === page.url} page={page}/>),

            <NavLink page={{
                name: "Log out",
                icon: LogOutIcon,
                isMainSection: true
            }} onClick={handleLogout}/>,
        ];
    }
}


