import {UI} from "../../../../../stem-core/src/ui/UIBase";
import {StyleSheet} from "../../../../../stem-core/src/ui/Style";
import {StemDate} from "../../../../../stem-core/src/time/Date";
import {ArrowLeftIcon, ArrowRightIcon} from "../../../../../blinkpay/SVGElements";
import {styleRule} from "../../../../../stem-core/src/decorators/Style";
import {registerStyle} from "../../../../../stem-core/src/ui/style/Theme";
import {monthFormat} from "../DateRangePickerConstants";
import {DashboardBaseInput} from "../../../../../core/ui/BaseInput";


class DateCalendarInterfaceStyle extends StyleSheet {
    transitionTime = 180; // TODO: Get rid of this pattern

    @styleRule
    buttonsContainer = {
        display: "flex",
        padding: "0 14px 24px",
    };

    @styleRule
    unclickable = {
        pointerEvents: "none",
    };

    @styleRule
    transition = {
        transition: `left ${this.transitionTime}ms ease`,
        pointerEvents: "none",
    };

    @styleRule
    monthLabel = {
        flex: 1,
        textAlign: "center",
        fontSize: 16,
        userSelect: "none",
    };

    @styleRule
    monthContainer = {
        position: "relative",
        display: "flex",
        justifyContent: "space-around",
    };

    @styleRule
    monthDisplay = {
        position: "relative",
        display: "flex",
        margin: "auto",
        width: 282,
    };

    @styleRule
    button = {
        width: 32,
        height: 32,
        margin: -4,
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        textAlign: "center",
        borderRadius: 80,
        ":hover": {
            background: this.themeProps.DATE_PICKER_DAY_LIGHT_GREY,
        },
        ">*": {
            height: "initial !important",
            margin: "auto",
        }
    };

    @styleRule
    buttonUnClickable = {
        pointerEvents: "none",
        opacity: "0.3",
    };

    @styleRule
    overflowHidden = {
        overflow: "hidden",
    };
}

const referenceDate = 1;

@registerStyle(DateCalendarInterfaceStyle)
export class DateCalendarInputInterface extends DashboardBaseInput {
    overflowHidden = false;
    transitioning = false;
    offset = 0;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            minDate: null,
            maxDate: null,
        }
    }

    // Overwrite the setValue in children classes to also update the referenceTime
    // setValue(value) {}

    addMonths(inputDate, count) {
        const date = new StemDate(inputDate);
        date.setDate(referenceDate);
        date.setMonth(date.getMonth() + count);
        return date;
    }

    renderTitleSection() {
        const {styleSheet} = this;
        const {minDate, maxDate} = this.options;
        const monthLabelTime = this.leftReferenceTime || this.rightReferenceTime || this.referenceTime;

        let leftArrowClassName = styleSheet.button;
        let rightArrowClassName = styleSheet.button;

        let date = new StemDate(this.referenceTime);
        date.setDate(1);
        date.setDate(date.getDate() - 1);

        // If the last date in the previous month is before the min date, make previous month inaccessible.
        if (minDate && (date.isBefore(minDate) && date.isSame(monthFormat) !== minDate.format(monthFormat))) {
            leftArrowClassName += styleSheet.buttonUnClickable;
        }

        date = this.addMonths(this.referenceTime, 1);
        // If the first date in the next month is after the max date, make previous month inaccessible.
        if (maxDate && (date.isAfter(maxDate) && date.format(monthFormat) !== maxDate.format(monthFormat))) {
            rightArrowClassName += styleSheet.buttonUnClickable;
        }

        return [
            <ArrowLeftIcon onClick={() => this.swipePrevious()} className={leftArrowClassName}/>,
            <div className={styleSheet.monthLabel}>
                {monthLabelTime.format(monthFormat)}
            </div>,
            <ArrowRightIcon onClick={() => this.swipeNext()} className={rightArrowClassName}/>
        ];
    }

    render() {
        const {styleSheet} = this;
        const leftOffset = -this.offset * 100 + "%";

        return [
            <div className={styleSheet.buttonsContainer + (this.transitioning ? styleSheet.unclickable : "")}>
                {this.renderTitleSection()}
            </div>,
            <div className={styleSheet.monthDisplay + (this.overflowHidden ? styleSheet.overflowHidden : "")}>
                <div className={styleSheet.monthContainer + (this.transitioning ? styleSheet.transition : "")}
                     style={{left: leftOffset}}>
                    {this.renderCalendarMonthComponents()}
                </div>
            </div>
        ]
    }

    swipeNext() {
        this.swipe(this.addMonths(this.referenceTime, 1));
    }

    swipePrevious() {
        this.swipe(this.addMonths(this.referenceTime, -1));
    }

    clearAnimations() {
        this.offset = 0;
        this.overflowHidden = false;
        this.transitioning = false;
        this.leftReferenceTime = null;
        this.rightReferenceTime = null;
        clearTimeout(this.animationTimeout1);
        clearTimeout(this.animationTimeout2);
    }

    swipe(referenceTime) {
        if (referenceTime.format(monthFormat) === this.referenceTime.format(monthFormat)) {
            return;
        }
        if (referenceTime.isBefore(this.referenceTime)) {
            this.overflowHidden = true;
            this.leftReferenceTime = referenceTime;
            this.offset = 1;
            this.redraw();
            this.animationTimeout1 = this.attachTimeout(() => {
                this.transitioning = true;
                this.offset = 0;
                this.redraw();
                this.animationTimeout2 = this.attachTimeout(() => {
                    this.transitioning = false;
                    this.overflowHidden = false;
                    this.referenceTime = this.leftReferenceTime;
                    this.leftReferenceTime = null;
                    this.redraw();
                }, this.styleSheet.transitionTime);
            })
        } else {
            this.overflowHidden = true;
            this.rightReferenceTime = referenceTime;
            this.offset = 0;
            this.redraw();
            this.animationTimeout1 = this.attachTimeout(() => {
                this.transitioning = true;
                this.offset = 1;
                this.redraw();
                this.animationTimeout2 = this.attachTimeout(() => {
                    this.overflowHidden = false;
                    this.transitioning = false;
                    this.referenceTime = this.rightReferenceTime;
                    this.rightReferenceTime = null;
                    this.offset = 0;
                    this.redraw();
                }, this.styleSheet.transitionTime);
            })
        }
    }

    renderCalendarMonthComponent(monthDate) {
        return null;
    }

    renderCalendarMonthComponents() {
        const components = [];
        if (this.leftReferenceTime) {
            components.push(this.renderCalendarMonthComponent(this.leftReferenceTime));
        }
        components.push(this.renderCalendarMonthComponent(this.referenceTime));
        if (this.rightReferenceTime) {
            components.push(this.renderCalendarMonthComponent(this.rightReferenceTime));
        }

        return components;
    }
}
