import {UI} from "../../../../../stem-core/src/ui/UIBase";
import {Money} from "../../../../../client/state/misc/Money";
import {TopUpPaymentStore} from "../../../../../client/state/TopUpPaymentStore";
import {EndpointPaginator} from "../../../../../client/state/EndpointPaginator";
import {Messages} from "../../../../../blinkpay/Messages";
import {capitalize} from "../../../../../blinkpay/Utils";
import {SimpleTable} from "../../../../../dashboard/ui/SimpleTable";
import {Card} from "../../ui/Card";
import {BlinkButton} from "../../../../../blinkpay/ui/Button";
import {RevertTopUpModal} from "./RevertTopUpModal";


export class UserDepositList extends UI.Element {
    render() {
        const {user} = this.options;
        const paginator = new EndpointPaginator(TopUpPaymentStore, "admin/top_up_payments/list", {
            userId: user.id,
            source: TopUpPaymentStore.Source.PAYMENT_METHOD,
        })

        const columns = [
            ["Id", item => item.id],
            ["PP Charge Id", item => item.paymentProcessorChargeId],
            ["Payment processor", item => item.getPaymentMethod()?.paymentProcessor],
            ["Payment method", item => {
                const paymentMethod = item.getPaymentMethod();
                return paymentMethod && Messages.usingCard(capitalize(paymentMethod.getBrand()), paymentMethod.getLastDigits());
            }],
            ["Amount", item => Money.format(item)],
            ["Status", item => item.status],
            ["Scope", item => item.resourceType ? `${item.resourceType} (${item.resourceId})` : item.isAutomatic ? "Auto top up" : "Manual top up"],
            ["Date", item => item.createdAt],
            ["Action", item => {
                return <BlinkButton onClick={() => RevertTopUpModal.show({user, charge: item})}>Revert</BlinkButton>;
            }],
        ]

        return (
            <Card title="Top ups">
                <SimpleTable columns={columns} paginator={paginator}/>
            </Card>
        );

    }
}
