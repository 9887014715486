import {MakeStore, StoreObject} from "../../../stem-core/src/state/Store";
import {field} from "../misc/StoreField";

class AnalyticsEvent extends StoreObject {
    @field(String) type;
    @field(String) origin;
    @field(Date) createdAt;
    @field(Number) timestamp;
    @field(Number) localSessionTime;

    /// TODO @branch some more fields, when done

    @field(Object) payload;
}

export const AnalyticsEventStore = MakeStore("AnalyticsEvent", AnalyticsEvent);
