import {UI} from "../../../../../stem-core/src/ui/UIBase";
import {Money} from "../../../../../client/state/misc/Money";
import {TopUpPaymentStore} from "../../../../../client/state/TopUpPaymentStore";
import {EndpointPaginator} from "../../../../../client/state/EndpointPaginator";
import {SimpleTable} from "../../../../../dashboard/ui/SimpleTable";
import {Card} from "../../ui/Card";


// TODO: a lot of overlap with the Deposit list
export class UserWalletCreditList extends UI.Element {
    render() {
        const {user} = this.options;
        const paginator = new EndpointPaginator(TopUpPaymentStore, "admin/top_up_payments/list", {
            userId: user.id,
            source: TopUpPaymentStore.Source.CREDIT,
        });

        const columns = [
            ["Id", topUp => topUp.id],
            ["Wallet Id", topUp => topUp.walletId],
            ["Amount", topUp => Money.format(topUp)],
            ["Currency", topUp => topUp.getCurrency()],
            ["Status", topUp => topUp.status],
            ["Date", topUp => topUp.createdAt],
        ]

        return (
            <Card title="Free credits">
                <SimpleTable columns={columns} paginator={paginator} />
            </Card>
        );
    }
}
