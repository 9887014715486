import {UI} from "../../../stem-core/src/ui/UIBase";
import {LoadingSpinner} from "../LoadingSpinner";

export let defaultLoadingRender = (options, uiElement) => <LoadingSpinner />;
export let defaultErrorRender = (options, uiElement) =><strong>Loading error: {uiElement.loaderError}</strong>;

export function loadBefore(loader, options={}) {
    const loadingRender = options.loadingRender || defaultLoadingRender;
    const errorRender = options.errorRender || defaultErrorRender;

    // TODO @Mihai overwrite prototype
    return (UIClass) => class UIElement extends UIClass {
        render() {
            if (!this.finishedLoading) {
                return loadingRender(this.options, this);
            }

            if (this.loaderError) {
                return errorRender(this.options, this);
            }

            return super.render();
        }

        async onMount() {
            super.onMount();
            this.finishedLoading = false;
            try {
                this.loaderResponse = await loader(this.options, this); // Forward our options to the loader
                this.finishedLoading = true;
            } catch (error) {
                console.log("Loading Error: ", error);
                this.loaderError = error;
            }

            this.redraw();
        }
    }
}
