import {Dispatchable} from "../../../stem-core/src/base/Dispatcher";
import {StemDate} from "../../../stem-core/src/time/Date";
import {FIELD_LOADERS} from "../misc/StoreField";
import {apiConnection} from "../../connection/BlinkApiConnection";

export class FileResource extends Dispatchable {
    constructor(fileResourceId) {
        super();
        if (!fileResourceId) {
            console.warn("Should not instantiate without a fileResourceId");
        }
        this.fileResourceId = fileResourceId;
    }

    async getURL() {
        if (this.response) {
            // TODO handle if the url is expired
            return this.response.url;
        }
        if (!this.fileResourceId) {
            throw "Missing file resource Id";
        }
        this.response = await apiGetFileResourceURL(this.fileResourceId);
        this.response.expiresAt = new StemDate(this.response.expiresAt);
        return this.response.url;
    }

    // TODO this should cache?
    static optionally(fileResourceId) {
        return fileResourceId && new FileResource(fileResourceId);
    }
}

FIELD_LOADERS[FileResource] = (value) => FileResource.optionally(value);
FIELD_LOADERS[FileResource].wrapIdField = true;


export async function apiGetFileResourceURL(fileResourceId) {
    return apiConnection.get("/merchant/get_file_resource_url/", {fileId: fileResourceId});
}