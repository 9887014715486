import {UI} from "../../../../stem-core/src/ui/UI";
import {UserSelector} from "../../../../dashboard/ui/UserSelector";
import {UserProfileStore, UserType} from "../../../../client/state/UserProfileStore";

export class AdminMerchantUserSelector extends UserSelector {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            btnText: "Choose merchant",
            notSelectedLabel: "No merchant selected.",
        }
    }

    getDefaultModalOptions() {
        return {
            ...super.getDefaultModalOptions(),
            title: "Select merchant",
            description: null,
            paginatorStore: UserProfileStore,
            paginatorEndpoint: "/admin/users/list/",
            filters: {userType: [UserType.MERCHANT.getValue()]},
            allowUserCreation: false,
            createUserBtnLabel: "Add merchant",
        }
    }
}
