import {UI} from "stem-core/src/ui/UI";
import {Card} from "../../ui/Card";
import {CardItems} from "../../ui/CardItems";
import {Money} from "../../../../../client/state/misc/Money";


export class UserPreferences extends UI.Element {
    render() {
        const {user} = this.options;
        const preferences = user.getPreferences();
        const currency = preferences.getDefaultCurrency();

        return (
            <Card title="Preferences">
                <CardItems
                    items={[
                        ["Auto pay enabled", preferences.autoPayEnabled ? "true" : "false"],
                        ["Article price limit", Money.format(preferences.articlePriceLimit, currency)],
                        ["Auto top up enabled", preferences.autoTopUpEnabled ? "true" : "false"],
                        ["Auto top up amount", Money.format(preferences.autoTopUpAmount, currency)],
                        ["Auto top up threshold", Money.format(preferences.autoTopUpThreshold, currency)],
                        ["Max wallet balance", Money.format(preferences.maxWalletBalance, currency)],
                        ["Top up daily limit", Money.format(preferences.topUpDailyLimit, currency)],
                        ["Top up monthly limit", Money.format(preferences.topUpMonthlyLimit, currency)],
                    ]}
                />
            </Card>
        );
    }
}


