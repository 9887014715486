import {UI} from "stem-core/src/ui/UIBase";
import {MerchantStaffList} from "./MerchantStaffList";
import {EditableCard} from "../ui/EdiableCard";
import {apiAdminFetchMerchant, apiUpdateMerchant} from "../../../../client/state/merchant/AdminMerchantStore";
import {apiAdminUpdateMerchantFees} from "../../../../client/state/merchant/MerchantFeesStore";
import {loadBefore} from "../../../../blinkpay/ui/decorators/LoadBefore";
import {DashboardTitle} from "../../../../dashboard/common/DashboardTitle";

async function loadMerchant(options) {
    const merchantId = options.args[0];
    options.merchant = await apiAdminFetchMerchant(merchantId);
}

class MerchantPanel extends UI.Element {
    render() {
        const {merchant} = this.options;
        const merchantFees = merchant.getFees();

        return [
            <div style={{fontSize: 18, padding: 8}}>
                Legal Entity: {merchant.legalEntity}
            </div>,
            <EditableCard values={merchant}
                          title="Merchant Profile"
                          editableFields={["name", "alias", "url", "wideLogoUrl", "cardStatementName", "allowPurchases", "allowSubscriptions", "allowDonations"]}
                          onUpdate={(data) => apiUpdateMerchant({merchantId: merchant.id, ...data})}
            />,
            <EditableCard values={merchantFees}
                          title="Merchant Fees"
                          editableFields={["purchaseFee", "subscriptionFee", "donationFee"]}
                          onUpdate={(data) => apiAdminUpdateMerchantFees({merchantId: merchant.id, ...data})}
            />,
            <MerchantStaffList merchant={merchant}/>,
        ]
    }
}


@loadBefore(loadMerchant)
export class MerchantPage extends UI.Element {
    render() {
        const {merchant} = this.options;

        return [
            <DashboardTitle title={"Edit merchant " + merchant.name}/>,
            <MerchantPanel title="Merchant" merchant={merchant}/>
        ];
    }
}
