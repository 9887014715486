import {UI} from "../../../../stem-core/src/ui/UIBase";
import {
    MerchantPayoutStore,
    apiAdminApprovePayout,
    apiAdminGeneratePayouts,
    apiAdminGenerateStripeReport, apiAdminGetPayoutMonthSummaryReport
} from "../../../../client/state/merchant/MerchantPayoutStore";
import {Card} from "../ui/Card";
import {SimpleTable} from "../../../../dashboard/ui/SimpleTable";
import {autoredraw} from "../../../../stem-core/src/decorators/AutoRedraw";
import {Button} from "../../../../stem-core/src/ui/button/Button";
import {Select} from "../../../../stem-core/src/ui/input/Input";
import {longMonthNames, StemDate} from "../../../../stem-core/src/time/Date";
import {TimeUnit} from "../../../../stem-core/src/time/Duration";
import {EndpointPaginator} from "../../../../client/state/EndpointPaginator";

@autoredraw
class PayoutActions extends UI.Element {
    render() {
        const {payout} = this.options;

        if (payout.status == "waiting_for_approval") {  // TODO: use enum
            return [
                // TODO: figure out some better button presets
                <Button
                    style={{padding: 8, fontSize: 14}}
                    onClick={() => apiAdminApprovePayout(payout.id)}>
                Approve
                </Button>
            ];
        }
    }
}

function getYearSelectOptions() {
    const years = [];
    for (let year = new StemDate().getFullYear(); year >= 2019; year--) {
        years.push(year);
    }
    return years;
}

function getMonthSelectOptions() {
    return longMonthNames.map((name, index) => ({
        index: index + 1,
        toString: () => name,
    }));
}

@autoredraw(MerchantPayoutStore)
export class PayoutsPage extends UI.Element {
    paginator = new EndpointPaginator(MerchantPayoutStore, "/admin/payouts/list/", {
        lastPaymentDate: this.getLastPaymentDateFilter(),
        pageSize: 50,
    });

    getDefaultSelectedYear() {
        return new StemDate().getFullYear();
    }

    getDefaultSelectedMonth() {
        return new StemDate().subtract(TimeUnit.MONTH).getMonth() + 1;
    }

    getLastPaymentDateFilter() {
        const monthDate = new StemDate(
            this.yearSelect?.getValue() ?? this.getDefaultSelectedYear(),
            this.monthSelect?.getValue().index ?? this.getDefaultSelectedMonth()
        );
        return {
            start_date: monthDate.getStartOfMonth(),
            end_date: monthDate.getEndOfMonth(),
        };
    }

    render() {
        const currentSelectedYear = this.yearSelect?.getValue() || this.getDefaultSelectedYear();
        const currentSelectedMonth = this.monthSelect?.getValue().index || this.getDefaultSelectedMonth();

        const columns = [
            ["Id", payout => payout.id],
            ["Merchant", payout => payout.merchant],
            // TODO: This is a hack, start date might not be accurate.
            ["Payments period", payout => `${payout.lastPaymentDate.clone().subtract(TimeUnit.MONTH)} - ${payout.lastPaymentDate}`],
            ["Amount", payout => payout.paidAmount],
            ["Status", payout => payout.status],
            ["Actions", payout => <PayoutActions payout={payout} />],
        ];

        const yearSelectOptions = getYearSelectOptions();
        const monthSelectOptions = getMonthSelectOptions();

        return [
            <Card title="Actions">
                <Select ref="monthSelect" options={monthSelectOptions} selected={monthSelectOptions[currentSelectedMonth - 1]}
                        style={{margin: 8}}
                        onChange={() => this.updateFilters()}
                />
                <Select ref="yearSelect" options={yearSelectOptions} selected={currentSelectedYear}
                        style={{margin: 8}}
                        onChange={() => this.updateFilters()}
                />
                <div style={{margin: 8}}>
                    <Button onClick={apiAdminGeneratePayouts}>
                        Generate payouts
                    </Button> (Generate the monthly payouts, to be approved by an admin.)
                </div>
                <div style={{margin: 8}}>
                    <Button onClick={apiAdminGenerateStripeReport}>
                        Download Internal Accounting CSV
                    </Button>
                </div>
                <div style={{margin: 8}}>
                    <Button onClick={apiAdminGetPayoutMonthSummaryReport}>
                        Download latest month payout ZIP summary
                    </Button>
                </div>
            </Card>,
            <Card title="Payouts">
                <SimpleTable paginator={this.paginator} columns={columns}/>
            </Card>
        ]
    }

    updateFilters() {
        this.paginator.updateFilter({
            lastPaymentDate: this.getLastPaymentDateFilter(),
        });
    }
}
