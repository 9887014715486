import {apiConnection} from "../../client/connection/BlinkApiConnection";
import {Dispatchable} from "../../stem-core/src/base/Dispatcher";
import {NOOP_FUNCTION} from "../../stem-core/src/base/Utils";
import {authService, AuthService} from "../../client/connection/services/AuthService";
import {ApiErrors} from "../../client/connection/ApiErrors";
import {MerchantStore} from "../../client/state/MerchantStore";
import {MerchantSDKSettingsStore} from "../../client/state/merchant/MerchantSDKSettingsStore";
import {LocalStorageMap} from "../../stem-core/src/base/StorageMap";

const MERCHANT_ID_STORAGE_KEY = "merchantId";

// TODO @branch keep this somewhere, this is the working version. Should be name differently, it's not active.
export function getActiveSettingsVersion(merchant) {
    const sdkVersions = MerchantSDKSettingsStore.filterBy({merchant}).sort((a, b) => b.id - a.id);
    return sdkVersions[0]; // The highest id one
}

// TODO: probably redesign how we use this class
export class MerchantService extends Dispatchable {
    initiatedMerchantDataRequest = false;
    finishedMerchantDataRequest = false;
    currentMerchantId = null;

    constructor() {
        super();
        authService.addListener(AuthService.EventTypes.CLEAR_CURRENT_INFO, () => this.clearMerchantInfo());
    }

    setCurrentMerchantId(merchantId) {
        try {
            localStorage.setItem(MERCHANT_ID_STORAGE_KEY, merchantId);
        } catch (e) {
        } finally {
            this.currentMerchantId = merchantId;
        }
    }

    getMerchantId() {
        try {
            return parseInt(localStorage.getItem(MERCHANT_ID_STORAGE_KEY));
        } catch (e) {
            return this.currentMerchantId;
        }
    }

    getMerchant() {
        return MerchantStore.getByIdOrAlias(this.getMerchantId());
    }

    getLocalStorageMap() {
        return new LocalStorageMap(this.getMerchantId());
    }

    async fetchMerchantData() {
        this.initiatedMerchantDataRequest = true;
        try {
            const response = await apiConnection.get("/merchant/merchant_data/", {});
            this.dispatch("merchantDataFetched");

            return response;
        } catch (error) {
            if (error.code === ApiErrors.AUTHENTICATION_FAILED || error.code === ApiErrors.NOT_AUTHENTICATED) {
                authService.clearCurrentInfo();
            }
            throw error;
        } finally {
            this.updateMerchantId();
            this.finishedMerchantDataRequest = true;
            this.dispatch("merchantDataRequestFinished");
        }
    }

    onMerchantFetched(callback) {
        this.addListener("merchantDataFetched", callback);
    }

    updateMerchantId() {
        // Save merchantId only when the logged in user has access to a single merchant.
        const merchants = MerchantStore.all();
        if (merchants.length === 1) {
            this.setCurrentMerchantId(merchants[0].id);
        }
    }

    onMerchantDataRequestFinished(callback) {
        this.addListener("merchantDataRequestFinished", callback);
    }

    isMerchantDataRequestFinished() {
        return this.finishedMerchantDataRequest;
    }

    async ensureMerchantDataRequested(callback = NOOP_FUNCTION) {
        if (this.isMerchantDataRequestFinished()) {
            callback();
            return;
        }
        this.onMerchantDataRequestFinished(callback);

        if (!this.initiatedMerchantDataRequest) {
            await this.fetchMerchantData();
        }
    }

    clearMerchantInfo() {
        this.initiatedMerchantDataRequest = false;
        this.finishedMerchantDataRequest = false;
        this.currentMerchantId = null;
        localStorage.removeItem(MERCHANT_ID_STORAGE_KEY);
    }
}

// TODO @Mihai stop using this!!!!
export const merchantService = new MerchantService();
