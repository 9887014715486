import {UI} from "../../../stem-core/src/ui/UIBase";

import {MerchantCurrencyInput, MerchantCurrencyInputStyle} from "../MerchantInput";
import {styleRule, styleRuleInherit} from "../../../stem-core/src/decorators/Style";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme";
import {MerchantActiveButtonStyle} from "./MerchantActiveButton";
import {ActiveButton} from "../../ui/Button";
import {Messages} from "../../Messages";
import {NOOP_FUNCTION} from "../../../stem-core/src/base/Utils";
import {Money} from "../../../client/state/misc/Money";


export class CustomCurrencyInputStyle extends MerchantCurrencyInputStyle {
    @styleRuleInherit
    inputContainer = {
        background: "transparent",
        height: 40,
        border: "none",
        ":focus-within": {
            boxShadow: "none",
        },
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    };

    @styleRuleInherit
    currency = {
        color: this.themeProps.DONATE_ACTIVE_BUTTON_ACTIVE_COLOR,
        position: "static",
        fontSize: this.themeProps.MERCHANT_FONT_SIZE_LARGE,
        padding: 0,
        transform: "none",
    };

    @styleRuleInherit
    input = {
        caretColor: this.themeProps.DONATE_ACTIVE_BUTTON_ACTIVE_COLOR,
        color: this.themeProps.DONATE_ACTIVE_BUTTON_ACTIVE_COLOR,
        fontSize: this.themeProps.MERCHANT_FONT_SIZE_LARGE,
        fontWeight: this.themeProps.MERCHANT_FONT_WEIGHT_BOLD,
        paddingRight: 10,
        paddingLeft: 0,
        display: "block",
        flex: 0.8,
        flexBasis: "38px",
    };

    @styleRuleInherit
    element = {
        margin: 0,
    };

    @styleRule
    spaceFill = {
        flex: 1,
    };
}

@registerStyle(CustomCurrencyInputStyle)
export class CustomCurrencyInput extends MerchantCurrencyInput {
    render() {
        return [
            <div className={this.styleSheet.spaceFill} />,
            super.render(),
        ];
    }
}

class CustomAmountButtonStyle extends MerchantActiveButtonStyle {
    @styleRule
    invalid = {
        border: () => "1px solid " + this.themeProps.MERCHANT_ERROR,
        ":hover": {
            borderColor: this.themeProps.MERCHANT_ERROR,
        }
    };
}

@registerStyle(CustomAmountButtonStyle)
export class CustomAmountButton extends ActiveButton {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            CustomCurrencyInputClass: CustomCurrencyInput,
            label: Messages.other,
            active: true,
            currency: null,
            minAmount: null,
            maxAmount: null,
            defaultAmount: null,
            onInputChange: NOOP_FUNCTION,
            onInputBlur: NOOP_FUNCTION,
            onInputError: NOOP_FUNCTION,
            onValidInput: NOOP_FUNCTION,
        }
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        if (this.invalid && this.options.active) {
            attr.addClass(this.styleSheet.invalid);
        }
    }

    getLabel() {
        return this.options.active ? "" : this.options.label;
    }

    handleInputChange() {
        this.options.onInputChange(this.getInputValue());
    }

    getInputValue() {
        return this.currencyInput && this.currencyInput.getValue();
    }

    render() {
        const {currency, minAmount, maxAmount, defaultAmount, onInputError, onValidInput, CustomCurrencyInputClass}
            = this.options;
        const inputAttributes = {
            testId: "customAmountButtonInput",
            min: currency.amountToMainUnits(minAmount),
            max: currency.amountToMainUnits(maxAmount),
        };

        const validInputCallback = () => {
            this.invalid = false;
            onValidInput();
            this.redraw();
        };

        const onInputBlur = () => {
            validInputCallback();
            this.options.onInputBlur();
        };

        const invalidInputCallback = (insertedValue) => {
            const insertedAmountInMainUnits = parseFloat(insertedValue);
            if (insertedAmountInMainUnits < inputAttributes.min || insertedAmountInMainUnits > inputAttributes.max) {
                const errorMessage = Messages.donationInvalidAmountError(
                    new Money(minAmount, currency),
                    new Money(maxAmount, currency),
                );
                this.invalid = true;
                onInputError(errorMessage);
                this.redraw();
            }
        };

        return this.options.active ?
            <CustomCurrencyInputClass ref="currencyInput" currency={this.options.currency}
                                      initialValue={defaultAmount || ""} // Don't display "0", display "".
                                      onInvalidInput={invalidInputCallback}
                                      onValidInput={validInputCallback}
                                      onBlur={onInputBlur}
                                      inputAttributes={inputAttributes}
                                      onChange={() => this.handleInputChange()}
            /> : null;
    }

    onMount() {
        super.onMount();

        const focusCurrencyInput = (event) => {
            event.preventDefault();
            event.stopPropagation();

            if (this.currencyInput) {
                this.currencyInput.focus();
            }
        };
        this.addNodeListener("click", (event) => focusCurrencyInput(event));
        this.addNodeListener("touchend", (event) => focusCurrencyInput(event));
    }
}
