import {UI} from "../../../../stem-core/src/ui/UIBase";
import {Route, Router, TerminalRoute} from "../../../../stem-core/src/ui/Router";
import {UsersPage} from "../users/UsersPage";
import {DashboardSidePanel} from "../../../../dashboard/DashboardSidePanel";
import {AdminMerchantsPage} from "../merchants/AdminMerchantsPage";
import {APIEndpointsSummary} from "../autogenerated-docs/APIEndpointsSummary";
import {LatestPaymentsTable} from "../autogenerated-docs/LatestPaymentsTable";
import {EmailTemplatesPage} from "../emailTemplates/EmailTemplatesPage";
import {AdminUserPanel} from "../users/UserPage";
import {AdminLoginPage} from "./LoginPage";
import {apiClient, apiConnection} from "../../../../client/connection/BlinkApiConnection";
import {LoadingSpinner} from "../../../../blinkpay/ui/LoadingSpinner";
import {MerchantPage} from "../merchants/MerchantPage";
import {Link} from "../../../../stem-core/src/ui/UIPrimitives";
import {singlePageLinkOnMount} from "../../../../stem-core/src/app/StemApp";
import {Theme} from "../../../../stem-core/src/ui/style/Theme";
import {PayoutsPage} from "../payouts/PayoutsPage";
import {FileSaver} from "../../../../stem-core/src/base/FileSaver";
import {registerDashboardStyleSheets, DashboardPageStyle} from "../../../../dashboard/common/theme/DashboardTheme";
import {Page} from "../../../../dashboard/PageHandler";
import {
    AnalyticsIcon, EmailIcon, MerchantIcon, ReportsIcon,
    RevenueIcon,
    UsersIcon
} from "../../../../dashboard/NavSidePanelIcons";
import {BlinkPayLogoText} from "../../../../blinkpay/SVGElements";
import {LandingPage} from "./LandingPage";
import {CDSRequestsPage} from "../merchants/CDSRequestsPage";
import {CompliancePage} from "./CompliancePage";
import {AnalyticsEventsPage} from "../analytics/AnalyticsEventsPage";
import {BlinkLogMessagesPage} from "../analytics/LogMessagesPage.jsx";
import {authService} from "../../../../client/connection/services/AuthService.js";
import {iFrameConnection} from "../../../../blinkpay/services/IFrameConnection.js";
import {IFrameMessages} from "../../../../blink-sdk/messaging/IFrameMessages.js";
import {AuthHelper} from "../../../../blinkpay/AuthHelper.js";
import {
    LoginRedirectCallback
} from "../../../../blinkpay/website/unauthenticated/socialaccount/LoginRedirectCallback.jsx";
import {APP_NAME} from "../../../../blinkpay/Constants.js";


const AdminPages = {
    MERCHANTS: new Page("merchants", "Merchants", AdminMerchantsPage, {icon: MerchantIcon}),
    USERS: new Page("users", "Users", UsersPage, {icon: UsersIcon}),
    ANALYTICS_EVENTS: new Page("analytics-events", "Analytics Events", AnalyticsEventsPage, {icon: AnalyticsIcon}),
    LOG_MESSAGES: new Page("log-messages", "Log Messages", BlinkLogMessagesPage, {icon: ReportsIcon}),
    // ANALYTICS: new Page("analytics", "Analytics", AnalyticsPage, {icon: AnalyticsIcon}),
    // BUSINESS_INTELLIGENCE: new Page("business-intelligence", "Business Intelligence", BusinessIntelligencePage),
    EMAIL_TEMPLATES: new Page("email-templates", "Email Templates", EmailTemplatesPage, {icon: EmailIcon}),
    LATEST_TRANSACTIONS: new Page("latest-transactions", "Latest Transactions", LatestPaymentsTable, {icon: RevenueIcon}),
    API_DETAILS: new Page("api-details", "API Details", APIEndpointsSummary),
    PAYOUTS: new Page("payouts", "Payouts", PayoutsPage),
    CDS_REQUESTS: new Page("cds-requests", "CDS Requests", CDSRequestsPage),
    COMPLIANCE: new Page("compliance", "Compliance", CompliancePage),
}

const UNAUTH_ROUTES = new Route(null, AdminLoginPage, [
    new TerminalRoute(["login-redirect"], LoginRedirectCallback),
    new TerminalRoute(["%s"], AdminLoginPage),
])


const ROUTES = new Route(null, LandingPage, [
    AdminPages.MERCHANTS.toRoute([
        new Route("%s", MerchantPage, "Merchant"),
    ]),
    AdminPages.USERS.toRoute([
        new TerminalRoute("%s", AdminUserPanel),
    ]),
    ...Array.from(Object.values(AdminPages)).map(page => page.toRoute()),
]);


export class AdminApp extends UI.Element {
    haveLoaded = false;

    static init() {
        this.initializeApiConnection();
        this.initializeTheme();

        Page.titleSuffix = " - Blink Admin";
        Link.prototype.onMount = singlePageLinkOnMount;
        this.create(document.body);

        // TODO @branch @auth use this everywhere in the same way
        iFrameConnection.addListener(IFrameMessages.USER_TOKEN_UPDATE, ({token}) => {
            const {t, e} = JSON.parse(token);
            authService.token.setToken(t, e);
            window.location.href = "/"; // Admin need to refresh
        });
    }

    static initializeApiConnection() {
        // TODO: centralize this
        apiConnection.ajaxHandler.addPostprocessor((response, xhrPromise) => {
            const {error} = response;
            if (error) {
                throw error;
            }
        });

        /// For now, always show a download pop-up
        apiConnection.ajaxHandler.addPostprocessor((response, xhrPromise) => {
            const {attachment} = response;
            if (!attachment) {
                return;
            }
            // Do this is another flow, to not interrupt logic
            setTimeout(() => {
                const {content, fileName, encodingType} = attachment;
                let saveContent = content
                if (encodingType === "base64") {
                    const binaryString = atob(content);
                    const len = binaryString.length;
                    const bytes = new Uint8Array(len);
                    for (let i = 0; i < len; i++) {
                        bytes[i] = binaryString.charCodeAt(i);
                    }
                    saveContent = new Blob([bytes.buffer], {type: "octet/stream"});
                }
                FileSaver.saveAs(saveContent, fileName);
            });
        })
    }

    static initializeTheme() {
        registerDashboardStyleSheets();

        // Admin specific theme properties
        Theme.setProperties({
            SIDEBAR_LINK_DEFAULT_COLOR: "#B9CBCD",
            SIDEBAR_LINK_ACTIVE_COLOR: "#FFF",
            SIDEBAR_BACKGROUND: "linear-gradient(257.14deg, #064049 28.76%, #07525E 54.31%, #064049 81.73%, #064049 98.33%)",
        });
    }

    async fetchData() {
        if (!authService.isAuthenticated()) {
            return;
        }

        const response = await apiClient.get("admin/app_data/");
        this.haveLoaded = true;
        this.redraw();
    }

    render() {
        authService.setLoginAppName("admin_dashboard"); // TODO @cleanup why the fuck do you care what my app is?
        if (!authService.isAuthenticated()) {
            return <Router routes={UNAUTH_ROUTES} />;
        }

        if (!this.haveLoaded) {
            return <LoadingSpinner/>;
        }

        const handleLogout = () => authService.logout();
        const styleSheet = DashboardPageStyle.getInstance();

        // This div is included because the global stylesheet is used to a certain type of dom structure. And that
        // dom structure needs an extra div until the side panel / main page.
        return [
            <div>
                <DashboardSidePanel ref={"sidebar"} pages={Object.values(AdminPages)} handleLogout={handleLogout}>
                    <BlinkPayLogoText size={20} backgroundColor={"#DDD"}/>
                </DashboardSidePanel>
                <div className={styleSheet.mainPage}>
                    <Router ref="router" routes={ROUTES} style={{margin: "auto"}} onChange={() => {
                        const url = Router.parseURL();
                        this.sidebar.setURL(url[0]);
                    }}/>
                </div>
            </div>
        ]
    }

    onMount() {
        this.fetchData();
    }
}
