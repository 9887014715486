import {MakeStore, StoreObject} from "../../../../stem-core/src/state/Store";
import {field} from "../../misc/StoreField";

class MerchantCDSProduct extends StoreObject {
    @field("Merchant") merchant;
    @field(String) productId;
    @field(Boolean) canMakeSubscription;
    @field(Boolean) canMakeDonations;
    @field(Number) cdsAppId;

    toString() {
        return this.productId;
    }
}

export const MerchantCDSProductStore = MakeStore("MerchantCDSProduct", MerchantCDSProduct);
