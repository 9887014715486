import {UI} from "../../../stem-core/src/ui/UIBase";
import {MerchantCurrencyInput, MerchantCurrencyInputStyle} from "../../../blinkpay/common/MerchantInput";
import {Money} from "../../../client/state/misc/Money";
import {KeyEvent} from "../../../blinkpay/KeyEvent";
import {styleRuleInherit} from "../../../stem-core/src/decorators/Style";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme";
import {NumberInput} from "../../../stem-core/src/ui/input/Input";
import {isNumber} from "../../../stem-core/src/base/Utils";

class DashboardMoneyInputStyle extends MerchantCurrencyInputStyle {
    @styleRuleInherit
    element = {
        marginTop: null,
        marginBottom: null,
    };

    @styleRuleInherit
    inputContainer = {
        display: "flex",
        height: this.themeProps.INPUT_ELEMENT_DEFAULT_HEIGHT,
        fontSize: null,
    };

    @styleRuleInherit
    input = {
        flex: 1,
        paddingLeft: 8,
        width: null,
        fontSize: null,
    };

    @styleRuleInherit
    currency = {
        position: null,
        fontSize: null,
    };
}

// Unlike the user friendly one, this one always displays the number of decimal digits in the currency
// Put the currency symbol to the left
// TODO support a version of this where the currency can be edited
@registerStyle(DashboardMoneyInputStyle)
export class DashboardMoneyInput extends MerchantCurrencyInput {
    getDefaultOptions(options) {
        return {
            ...super.getDefaultOptions(options),
            initialValue: 0,
            placeholder: "0.00", // TODO @Mihai should depend on the currency
        }
    }

    // TODO @Mihai move this logic higher up, and probably not have a currency value
    setOptions(options) {
        super.setOptions(options);
        // TODO do we have a nice pattern on validation
        let {initialValue, currency} = this.options;
        if (isNumber(initialValue)) {
            initialValue = new Money(initialValue, currency);
        }
        if (initialValue instanceof Money) {
            currency = initialValue.getCurrency();
            initialValue = initialValue.toMainUnitString({includeSymbol: false});
        }
        this.options.initialValue = initialValue;
        this.options.currency = currency;  // A new Money value would update the currency
    }

    getValue(asMoney=true) {
        const {currency} = this.options;
        const amount = new Money(super.getValue(), currency);
        if (asMoney) {
            return amount;
        } else {
            return amount.getAmount();
        }
    }

    initValidationListeners() {
        let value = this.input.getValue().toString();

        const {subdivisionDecimalDigits} = this.options.currency;
        const decimalsRegex = subdivisionDecimalDigits ? `\\.[0-9]{${subdivisionDecimalDigits}}` : "";
        const inputPattern = new RegExp(`^0${decimalsRegex}$|^[1-9][0-9]*${decimalsRegex}$`); // Ensure we don't have trailing 0s.

        const isInputValid = (value) => {
            const {inputAttributes} = this.options;
            const {min, max} = inputAttributes;
            if (!value.match(inputPattern)) {
                return false;
            }
            if (min != null && parseFloat(value) < min) {
                return false;
            }
            return !(max != null && parseFloat(value) > max);
        };

        let selectionDelta = 0;
        let ignoreDotDeletion = false;
        this.input.addNodeListener("keydown", event => {
            const keyEvent = new KeyEvent(event);
            if (keyEvent.isBackspace()) {
                selectionDelta = -1;
            } else if (keyEvent.isDigit()) {
                selectionDelta = 1;
            } else {
                selectionDelta = 0;
            }
            ignoreDotDeletion = keyEvent.isBackspace() || keyEvent.isDelete();
        });

        this.input.addNodeListener("input", () => {
            let insertedValue = this.input.getValue().toString();
            const zeroValue = subdivisionDecimalDigits ? "0." + "0".repeat(subdivisionDecimalDigits) : "0";

            if (insertedValue.startsWith(".")) {
                // Convert .50 to 0.50
                insertedValue = "0" + insertedValue;
                this.setValue(insertedValue);
                this.input.node.setSelectionRange(1, 1);
            }

            if (insertedValue.startsWith(zeroValue) && insertedValue.length > zeroValue.length) {
                // If we're appending to a 0 value, we want the appended value
                // We'll handle any possible issues down bellow
                insertedValue = insertedValue.substr(zeroValue.length);
                this.setValue(insertedValue);
                this.input.node.setSelectionRange(insertedValue.length, insertedValue.length);
            }

            if (value === zeroValue && insertedValue.endsWith(zeroValue) && insertedValue.length - 1 === zeroValue.length) {
                insertedValue = insertedValue[0] + value.slice(1);
                this.setValue(insertedValue);
                this.input.node.setSelectionRange(1, 1);
            }
            if (!isInputValid(insertedValue)) {
                this.options.onInvalidInput(insertedValue);
                // Backspace that deletes the dot is ignored and only caret moves.
                if (value.replace(".", "") === insertedValue && ignoreDotDeletion) {
                    this.setValue(value);
                } else {
                    const partialMatchRegex = /[0-9]+(\.[0-9]*)?/;
                    let partialMatch = insertedValue.match(partialMatchRegex)?.[0] || "";

                    if (!partialMatch.includes(".")) {
                        partialMatch += ".";
                    }
                    partialMatch = "0" + partialMatch + "0".repeat(subdivisionDecimalDigits);
                    const strictMatchRegex = new RegExp(`[1-9][0-9]*${decimalsRegex}|0${decimalsRegex}`);
                    value = partialMatch.match(strictMatchRegex)[0]
                    this.setValue(value);
                }

                try {
                    this.selectionStart += selectionDelta;
                    this.input.node.setSelectionRange(this.selectionStart, this.selectionStart);
                } catch (e) {
                }
                return;
            }
            value = insertedValue;
            this.options.onValidInput(insertedValue);
            this.saveSelection();
            this.clearError();
        });
    }
}

export class DashboardPercentInput extends NumberInput {
}
