import {styleRule, StyleSheet} from "../../../stem-core/src/ui/Style";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme";
import {ActiveButton} from "../../ui/Button";

export class MerchantActiveButtonStyle extends StyleSheet {
    @styleRule
    button = {
        userSelect: "none",
        "-webkit-user-select": "none",
        outline: 0,
        height: 40,
        fontSize: this.themeProps.MERCHANT_FONT_SIZE_LARGE,
        cursor: "pointer",
        fontWeight: this.themeProps.MERCHANT_FONT_WEIGHT_REGULAR,
        border: () => `1px solid ${this.themeProps.MERCHANT_RADIO_BUTTON_SECONDARY_BORDER_COLOR}`,
        borderRadius: this.themeProps.MERCHANT_RADIO_BUTTON_BORDER_RADIUS,
        textAlign: "center",
        padding: 0,
        color: this.themeProps.MERCHANT_RADIO_BUTTON_SECONDARY_TEXT_COLOR,
        background: this.themeProps.MERCHANT_RADIO_BUTTON_SECONDARY_BACKGROUND,
        ":hover": {
            background: this.themeProps.MERCHANT_RADIO_BUTTON_SECONDARY_BACKGROUND_HOVER,
            borderColor: this.themeProps.MERCHANT_RADIO_BUTTON_SECONDARY_BORDER_COLOR_HOVER,
        },
    };

    @styleRule
    active = {
        color: this.themeProps.MERCHANT_RADIO_BUTTON_PRIMARY_TEXT_COLOR,
        fontWeight: this.themeProps.MERCHANT_FONT_WEIGHT_REGULAR,
        background: this.themeProps.MERCHANT_RADIO_BUTTON_PRIMARY_BACKGROUND,
        borderColor: this.themeProps.MERCHANT_RADIO_BUTTON_PRIMARY_BORDER_COLOR,
        boxShadow: "inset 0 0 0 1px " + this.themeProps.MERCHANT_RADIO_BUTTON_PRIMARY_BORDER_COLOR,
        ":hover": {
            background: this.themeProps.MERCHANT_RADIO_BUTTON_PRIMARY_BACKGROUND_HOVER,
            borderColor: this.themeProps.MERCHANT_RADIO_BUTTON_PRIMARY_BORDER_COLOR_HOVER,
            boxShadow: "inset 0 0 0 1px " + this.themeProps.MERCHANT_RADIO_BUTTON_PRIMARY_BORDER_COLOR_HOVER,
        },
    };
}

@registerStyle(MerchantActiveButtonStyle)
export class MerchantActiveButton extends ActiveButton {}

