import {UI} from "stem-core/src/ui/UI";
import {ConfirmationModal} from "../../../../../blinkpay/ui/ConfirmationModal";
import {LoadingSpinner} from "../../../../../blinkpay/ui/LoadingSpinner";
import {CardItems} from "../../ui/CardItems"
import {Money} from "../../../../../client/state/misc/Money";
import {Messages} from "../../../../../blinkpay/Messages";
import {capitalize} from "../../../../../blinkpay/Utils";


export class RevertTopUpModal extends ConfirmationModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: "Revert charge",
            confirmLabel: "Revert",
            confirmAction: () => this.revertTopUp(),
        }
    }

    async revertTopUp() {
        const {charge} = this.options;

        await this.makeRequest("/admin/top_up_payments/revert_charge/",{chargeId: charge.id});
    }

    render() {
        const {user, charge} = this.options;
        const paymentMethod = charge.getPaymentMethod();

        return [
            <CardItems items={[
                ["Email", user.email],
                ["Payment method", Messages.usingCard(capitalize(paymentMethod.getBrand()), paymentMethod.getLastDigits())],
                ["Top up amount", Money.format(charge)],
                ["Top up date", charge.createdAt]
            ]}
            />,
            this.waitingForResponse ? <LoadingSpinner/> : null,
        ];
    }
}
