import {UI} from "../../../../../../stem-core/src/ui/UIBase";
import {StyleSheet} from "../../../../../../stem-core/src/ui/Style";
import {CURRENT_PICK} from "../../DateRangePickerConstants";
import {DateRangeHeaderInput} from "../components/DateRangeHeaderInput";
import {Messages} from "../../../../../../blinkpay/Messages";
import {styleRule} from "../../../../../../stem-core/src/decorators/Style";
import {registerStyle} from "../../../../../../stem-core/src/ui/style/Theme";
import {LongRightArrowIcon} from "../../../../../SVGElements";
import {MessageElement} from "../../../../../../blinkpay/widget/ui/MessageElement";
import {DashboardBaseInput} from "../../../../../../core/ui/BaseInput";


class DateRangeHeader2Style extends StyleSheet {
    @styleRule
    active = {
        ">:nth-child(3)": {
            opacity: 1,
            pointerEvents: "initial",
        }
    };

    @styleRule
    label = {
        fontSize: 12,
        marginBottom: 6,
        fontWeight: "bold",
        color: this.themeProps.DATE_PICKER_INPUT_LABEL_COLOR,
    };

    @styleRule
    pickADate = {
        opacity: 0,
        pointerEvents: "none",
        marginTop: 12,
        fontSize: 12,
        color: this.themeProps.DATE_PICKER_INPUT_PICK_A_DATE_COLOR,
    };

    @styleRule
    arrow = {
        padding: "30px 12px",
    };

    @styleRule
    header = {
        display: "flex",
    }
}

@registerStyle(DateRangeHeader2Style)
export class DateRangeHeader extends DashboardBaseInput {
    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.header);
    }

    isStartDateActive() {
        return this.value.currentPick === CURRENT_PICK.startDate;
    }

    isEndDateActive() {
        return this.value.currentPick === CURRENT_PICK.endDate;
    }

    setValue(value) {
        super.setValue(value);
        if (!this.node) {
            return;
        }
        if (this.isStartDateActive()) {
            this.startDateInput.focus();
        } else if (this.isEndDateActive()) {
            this.endDateInput.focus();
        }
    }

    render() {
        const {styleSheet} = this;
        const {minDate, maxDate} = this.options;
        const {startDate, endDate} = this.value;

        return [
            <div className={(this.isStartDateActive() ? styleSheet.active : "")}>
                <div className={styleSheet.label}>{Messages.from}<span style={{opacity: 0.6, float: "right"}}>MM/DD/YYYY</span></div>
                <DateRangeHeaderInput ref="startDateInput" date={startDate} active={this.isStartDateActive()}
                                      minDate={minDate} maxDate={maxDate}/>
                <MessageElement message={Messages.pickStartDate} className={styleSheet.pickADate}/>
            </div>,
            <LongRightArrowIcon className={styleSheet.arrow}/>,
            <div className={(this.isEndDateActive() ? styleSheet.active : "")}>
                <div className={styleSheet.label}>{Messages.to}<span style={{opacity: 0.6, float: "right"}}>MM/DD/YYYY</span></div>
                <DateRangeHeaderInput ref="endDateInput" date={endDate} active={this.isEndDateActive()}
                                      minDate={minDate} maxDate={maxDate}/>
                <MessageElement message={Messages.pickEndDate} className={styleSheet.pickADate}/>
            </div>,
        ]
    }

    onDateSelection(date) {
        let {startDate, endDate, currentPick} = this.value;

        if (currentPick === CURRENT_PICK.startDate) {
            startDate = date;
            if (!endDate.isAfter(startDate)) {
                endDate = startDate;
            }
            currentPick = CURRENT_PICK.endDate;
        } else {
            endDate = date;
            if (!startDate.isBefore(endDate)) {
                startDate = endDate;
            }
            currentPick = CURRENT_PICK.startDate;
        }
        this.setAndDispatchValue({startDate, endDate, currentPick});
    }

    onMount() {
        this.startDateInput.addListener(DateRangeHeaderInput.EVENTS.focus, () => {
            if (this.value.currentPick !== CURRENT_PICK.startDate) {
                this.value.currentPick = CURRENT_PICK.startDate;
                this.dispatch("pickUpdate", this.value.currentPick);
            }
        });
        this.endDateInput.addListener(DateRangeHeaderInput.EVENTS.focus, () => {
            if (this.value.currentPick !== CURRENT_PICK.endDate) {
                this.value.currentPick = CURRENT_PICK.endDate;
                this.dispatch("pickUpdate", this.value.currentPick);
            }
        });

        this.startDateInput.addListener(DateRangeHeaderInput.EVENTS.dateUpdate, ({date}) => {
            this.onDateSelection(date);
        });
        this.endDateInput.addListener(DateRangeHeaderInput.EVENTS.dateUpdate, ({date}) => {
            this.onDateSelection(date);
        });
    }
}
