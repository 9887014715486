import {UI} from "../../../../../../stem-core/src/ui/UIBase";
import {styleRule, StyleSheet} from "../../../../../../stem-core/src/ui/Style";
import {Input} from "../../../../../../stem-core/src/ui/input/Input";
import {registerStyle} from "../../../../../../stem-core/src/ui/style/Theme";
import {StemDate} from "../../../../../../stem-core/src/time/Date";
import {dayFormat} from "../../DateRangePickerConstants";
import {KeyEvent} from "../../../../../../blinkpay/KeyEvent";


class DateRangeHeaderInput2Style extends StyleSheet {
    @styleRule
    element = {
        borderRadius: 4,
        border: () => "1px solid " + this.themeProps.DATE_PICKER_INPUT_INACTIVE_BORDER_COLOR,
        background: this.themeProps.WHITE,
        display: "flex",
        width: 126,
        padding: 4,
        cursor: "pointer",
        color: this.themeProps.DATE_PICKER_INPUT_COLOR,
    };

    @styleRule
    input = {
        background: "transparent",
        outline: "none",
        border: "none",
        minWidth: 0,
        fontSize: 12,
        fontWeight: "bold",
        textAlign: "center",
        color: "inherit",
        height: "initial !important",
    };

    @styleRule
    active = {
        border: () => "1px solid " + this.themeProps.DATE_PICKER_INPUT_ACTIVE_COLOR,
        boxShadow: this.themeProps.DATE_PICKER_INPUT_ACTIVE_BOX_SHADOW,
    };

    @styleRule
    invalid = {
        border: () => "1px solid " + this.themeProps.ERROR,
        color: this.themeProps.ERROR,
    };

    @styleRule
    forwardSlash = {
        color: () => this.themeProps.DATE_PICKER_INPUT_ACTIVE_COLOR + " !important",
    };
}

@registerStyle(DateRangeHeaderInput2Style)
export class DateRangeHeaderInput extends UI.Element {
    static EVENTS = {
        dateUpdate: "dateUpdate",
        focus: "focus",
    };

    monthValue = this.options.date.format("MM");
    dayValue = this.options.date.format("DD");
    yearValue = this.options.date.format("YYYY");
    focusCount = 0;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            date: null,
            active: null,
            minDate: null,
            maxDate: null,
        }
    }

    isValid() {
        const {minDate, maxDate} = this.options;
        const date = this.computeDate();
        if (!date.isValid()) {
            return false;
        }
        if (minDate && date.isBefore(minDate)) {
            return false;
        }
        if (maxDate && date.isAfter(maxDate)) {
            return false;
        }
        return true;
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.element);
        if (this.options.active) {
            attr.addClass(this.styleSheet.active);
        }
        if (!this.isValid()) {
            attr.addClass(this.styleSheet.invalid);
        }
    }

    setOptions(options) {
        const {date} = options;
        this.yearValue = date.format("YYYY");
        this.monthValue = date.format("MM");
        this.dayValue = date.format("DD");

        return super.setOptions(options);
    }

    render() {
        const {styleSheet} = this;

        return [
            <Input ref="monthInput" value={this.monthValue} className={styleSheet.input} style={{flex: 2.8}}/>,
            <div className={styleSheet.forwardSlash}>/</div>,
            <Input ref="dayInput" value={this.dayValue} className={styleSheet.input} style={{flex: 2.8}}/>,
            <div className={styleSheet.forwardSlash}>/</div>,
            <Input ref="yearInput" value={this.yearValue} className={styleSheet.input} style={{flex: 5}}/>
        ];
    }

    focus() {
        if (!this.focusCount) {
            this.monthInput.focus();
        }
    }

    computeDate() {
        return new StemDate(`${this.monthValue}/${this.dayValue}/${this.yearValue}`);
    }

    inputUpdated(forceDispatch=false) {
        this.redraw();
        if (this.focusCount) {
            return;
        }
        if (this.isValid()
            && (forceDispatch || this.computeDate().format(dayFormat) !== this.options.date.format(dayFormat))) {
            this.dispatch(DateRangeHeaderInput.EVENTS.dateUpdate, {date: this.computeDate()});
        }
    }

    addInputListeners(input, valueKey, maxCharacterCount, nextInput, previousInput) {
        let forceDispatch = false;
        const updateValue = () => {
            this[valueKey] = input.getValue();
            this.inputUpdated(forceDispatch);
            forceDispatch = false;
        };
        input.addNodeListener("keyup", event => {
            const key = new KeyEvent(event);
            if (key.isEnter()) {
                forceDispatch = true;
                input.blur();
            }
        });
        input.addNodeListener("blur", () => {
            this.focusCount -= 1;
            updateValue();
        });
        input.addNodeListener("focus", () => {
            this.focusCount += 1;
            this.dispatch(DateRangeHeaderInput.EVENTS.focus);
            updateValue();
        });
        input.addNodeListener("keydown", event => {
            const key = new KeyEvent(event);
            if (key.isArrowLeft()) {
                if (input.node.selectionStart === 0 && previousInput) {
                    previousInput.focus();
                }
                return;
            }
            if (key.isArrowRight()) {
                if (input.node.selectionStart === input.getValue().length && nextInput) {
                    nextInput.focus();
                }
                return;
            }
            if (key.isEnter() || key.isBackspace() || key.isDelete() || key.isTab()) {
                return;
            }
            // If the value isn't a digit or the input size gets too big, skip the event.
            if (!key.isDigit() || input.getValue().length === maxCharacterCount) {
                event.preventDefault();
                event.stopPropagation();
            }
        })
    }

    onMount() {
        super.onMount();

        this.addInputListeners(this.monthInput, "monthValue", 3, this.dayInput);
        this.addInputListeners(this.dayInput, "dayValue", 3, this.yearInput, this.monthInput);
        this.addInputListeners(this.yearInput, "yearValue", 6, null, this.dayInput);

        this.addClickListener(() => this.dispatch(DateRangeHeaderInput.EVENTS.focus));
    }
}
