import {UserProfile, UserProfileStore} from "./UserProfileStore";
import {PaymentMethodStore} from "./PaymentMethodStore";
import {apiClient} from "../connection/BlinkApiConnection";


class AdminUser extends UserProfile {
    getPaymentMethods() {
        return PaymentMethodStore.filter(item => item.userId === this.id);
    }
}

UserProfileStore.ObjectWrapper = AdminUser;

export async function apiAdminFetchUser(userId) {
    const response = await apiClient.get("admin/users/user_data/", {userId});
    return UserProfileStore.loadObjectFromResponse(response);
}
