import {UI} from "stem-core/src/ui/UI";
import {LoadingSpinner} from "../../../../../blinkpay/ui/LoadingSpinner";
import {ConfirmationModal} from "../../../../../blinkpay/ui/ConfirmationModal";
import {CreateLegalEntityForm} from "./CreateLegalEntityForm";


export class CreateLegalEntityModal extends ConfirmationModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: "Create Legal Entity",
            confirmAction: () => this.createLegalEntity(),
        }
    }

    async createLegalEntity() {
        const isFormValid = this.createLegalEntityForm && this.createLegalEntityForm.validateInputs();
        if (!isFormValid) {
            return;
        }

        await this.makeRequest("/admin/legal_entities/create", this.createLegalEntityForm.getInputValues());
    }

    render() {
        return [
            <CreateLegalEntityForm ref="createLegalEntityForm"/>,
            this.waitingForResponse ? <LoadingSpinner /> : null,
        ]
    }
}
