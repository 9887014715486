import {UI} from "../../../stem-core/src/ui/UIBase";

import {InputContainer, InputContainerStyle} from "./Base";
import {styleRule, StyleSheet} from "../../../stem-core/src/ui/Style";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme";
import {TimeUnit} from "../../../stem-core/src/time/Duration";
import {StemDate} from "../../../stem-core/src/time/Date";
import {ChevronDownIcon} from "../../../blinkpay/SVGElements";
import {formatDateRange} from "../../common/Utils";
import {PropertyCache} from "../../../stem-core/src/data-structures/PropertyCache";
import {PopupInput} from "./PopupInput";
import {DateRangePickerInput} from "./date-input/date-range-picker/DateRangePickerInput";
import {dashboardSettings} from "../../misc/DashboardSettings";

// Return the timezone aware current date
function now() {
    const timezone = dashboardSettings.queryTimezone;
    return timezone.convertFromUTC();
}

export class DateRangeInterval {
    constructor(name, getter, options={}) {
        this.name = name;
        this.getter = getter;
        Object.assign(this, options);
    }

    toString() {
        return this.name;
    }
}

Object.assign(DateRangeInterval, {
    TODAY: new DateRangeInterval( "Today", () => [now().getStartOfDay(), now().getEndOfDay()]),
    LAST_24_HOURS: new DateRangeInterval("Last 24 hours", () =>  [now().subtract(TimeUnit.DAY), now()]),
    YESTERDAY: new DateRangeInterval("Yesterday", () => [now().subtract(TimeUnit.DAY).getStartOfDay(), now().subtract(TimeUnit.DAY).getEndOfDay()]),
    LAST_7_DAYS: new DateRangeInterval("Last 7 Days", () => [now().getStartOfDay().subtract(6 * TimeUnit.DAY), now().getEndOfDay()]),
    LAST_30_DAYS: new DateRangeInterval("Last 30 Days", () => [now().getStartOfDay().subtract(TimeUnit.MONTH), now().getEndOfDay()]),
    LAST_3_MONTHS: new DateRangeInterval("Last 3 Months", () => [now().getStartOfDay().subtract(3 * TimeUnit.MONTH), now().getEndOfDay()]),
    YEAR_TO_DATE: new DateRangeInterval("Current Year", () => [now().getStartOfYear(), now().getEndOfDay()]),
    ALL_TIME: new DateRangeInterval("All Time", () => [new StemDate(2019, 0, 1), now().getEndOfYear()], {isAllTime: true}),
    CUSTOM: new DateRangeInterval("Custom", null),
});

DateRangeInterval.DEFAULT_VALUES = [
    DateRangeInterval.TODAY,
    DateRangeInterval.YESTERDAY,
    DateRangeInterval.LAST_7_DAYS,
    DateRangeInterval.LAST_30_DAYS,
    DateRangeInterval.LAST_3_MONTHS,
    DateRangeInterval.YEAR_TO_DATE,
];

const merchantAllTimeIntervalMap = new PropertyCache((merchant) => new DateRangeInterval("All Time", () => [merchant.createdAt, now()], {isAllTime: true}));

// For a specific merchant, all time is clipped.
// TODO @Mihai look into a @cached method that automatically creates the PropertyCache
export function MakeMerchantAllTimeInterval(merchant) {
    return merchantAllTimeIntervalMap.get(merchant);
}

// TODO: move this function
export function DefaultMerchantDateRangeIntervals(merchant) {
    return [
        ...DateRangeInterval.DEFAULT_VALUES,
        MakeMerchantAllTimeInterval(merchant),
        DateRangeInterval.CUSTOM,
    ]
}

class ListInputStyle extends StyleSheet {
    @styleRule
    item = {
        margin: "18px 0",
        textAlign: "center",
        color: this.themeProps.PAGE_TEXT_COLOR,
        padding: "0 30px",
        cursor: "pointer",
    };

    @styleRule
    active = {
        color: this.themeProps.DATE_PICKER_DAY_DARK_GREEN,
        fontWeight: 700,
    };
}


@registerStyle(ListInputStyle)
export class ListInput extends UI.Element {
    render() {
        const {styleSheet} = this;
        const {options, selected, onChange} = this.options;

        return options.map(option => {
            let className = styleSheet.item;
            if (option === selected) {
                className += styleSheet.active;
            }

            return <div className={className} onClick={() => onChange(option)}>
                {option}
            </div>
        })
    }
}

export class DateRangeInputStyle extends StyleSheet {
    @styleRule
    selectedOption = {
        whiteSpace: "nowrap",
    };

    @styleRule
    downIcon = {
        marginLeft: 4,
        marginTop: -1,
    }
}

@registerStyle(DateRangeInputStyle)
export class DateRangeInput extends UI.Element {
    getDefaultOptions(options) {
        const selectedInterval = options.selectedInterval || options.options?.[0];
        const [startDate, endDate] = (selectedInterval?.getter && selectedInterval.getter()) || [now(), now()];

        return {
            startDate,
            endDate,
            selectedInterval,
        }
    }

    // Not overwritten when copying the state
    getPreservedOptions() {
        return {
            startDate: this.options.startDate,
            endDate: this.options.endDate,
            selectedInterval: this.options.selectedInterval,
        }
    }

    getValue() {
        return this.getDateRange();
    }

    // The value for the API
    valueOf() {
        if (this.options.selectedInterval?.isAllTime) {
            // All time does not create a filter value by default
            return null;
        }
        const {startDate, endDate} = this.getValue();

        const timezone = dashboardSettings.queryTimezone;
        // Underscore case because this field is sometimes nested (createdAt filter for
        // subscriptions), and the API doesn't support automatic conversion from camel
        // case to underscores in nested fields.
        // TODO we need to properly standardize this
        return {
            start_date: timezone.convertToUTC(startDate).unix(),
            end_date: timezone.convertToUTC(endDate).unix(),
        };
    }

    getDateRange() {
        let {startDate, endDate} = this.options;

        return {
            startDate,
            endDate,
            duration: endDate.diff(startDate),
            // TODO add numDays as the number of distinct days impacted?
        }
    }

    changeSelectedInterval(selectedInterval) {
        this.updateOptions({selectedInterval});

        if (selectedInterval.getter === null) {
            // We want to do a custom interval - should only be one
            this.requestDateRangePicker(this.formattedDateRange);
            return;
        }

        const [startDate, endDate] = selectedInterval.getter();
        this.changeDateRange({startDate, endDate, selectedInterval});
    }

    changeDateRange({startDate, endDate, selectedInterval}) {
        startDate = StemDate.toDate(startDate);
        endDate = StemDate.toDate(endDate);
        this.updateOptions({startDate, endDate, selectedInterval});

        this.options.onChange({
            startDate,
            endDate,
        });
    }

    async requestIntervalListInput(anchor) {
        const {options, selectedInterval} = this.options;

        const newSelectedInterval = await PopupInput.prompt(ListInput, {
            options,
            selected: selectedInterval,
        }, {anchor});

        if (newSelectedInterval) {
            this.changeSelectedInterval(newSelectedInterval);
        }
    }

    async requestDateRangePicker(anchor) {
        const {startDate, endDate} = this.options;

        const dateRange = await PopupInput.prompt(DateRangePickerInput, {
            initialValue: {startDate, endDate, currentPick: 0},
        }, {anchor});

        if (dateRange) {
            this.changeDateRange(dateRange);
        }
    }

    render() {
        const {styleSheet} = this;
        const {selectedInterval, startDate, endDate} = this.options;
        const formattedDateRange = formatDateRange(startDate, endDate);
        const inputElementStyle = InputContainerStyle.getInstance();

        return <InputContainer className={inputElementStyle.separatedElements}>
            <div onClick={(event, element) => this.requestIntervalListInput(element)} style={{display: "flex"}}>
                <div className={styleSheet.selectedOption}>{selectedInterval || "Custom"}</div>
                <div className={styleSheet.downIcon}><ChevronDownIcon color={styleSheet.themeProps.PAGE_TEXT_COLOR} /></div>
            </div>
            <div ref="formattedDateRange"
                 style={{fontWeight: 700}}
                 onClick={(event, element) => this.requestDateRangePicker(element)} >
                {formattedDateRange}
            </div>
        </InputContainer>
    }
}
