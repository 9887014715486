import {UI} from "../../../stem-core/src/ui/UIBase";

import {AnchoredPopup, AnchoredPopupStyle} from "../../../blinkpay/ui/AnchoredPopup";
import {styleRuleInherit} from "../../../stem-core/src/decorators/Style";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme";
import {Direction} from "../../../stem-core/src/ui/Constants";

// TODO have an invisible scrim, that also passes through all click events (so another element can be toggled)
// Base class that wraps an input
// TODO this should probably be merged with the Modal class in the future (at least in a base class)

export class DashboardAnchoredPopupStyle extends AnchoredPopupStyle {
    @styleRuleInherit
    popup = {
        background: this.themeProps.WHITE, // TODO @branch it's a bit strange that this is only don
        border: "1px solid rgba(0,0,0,0.1)",
        boxShadow: "0 0 5px 0 rgba(0,0,0,0.2)",
        borderRadius: this.themeProps.CARD_BORDER_RADIUS,
        padding: 8,
    };
}

@registerStyle(DashboardAnchoredPopupStyle)
export class DashboardAnchoredPopup extends AnchoredPopup {
    getDefaultOptions(options) {
        return {
            ...super.getDefaultOptions(options),
            direction: Direction.DOWN,
            offset: 10,
        };
    }
}


export class PopupInput extends DashboardAnchoredPopup {
    hide(response=null) {
        if (this.resolvePromise) {
            this.attachTimeout(() => this.resolvePromise(response), this.styleSheet.transitionTime);
        }
        super.hide();
    }

    handleInputChange(value) {
        this.hide(value);
    }

    // Will show an input, and on a second call with the same anchor will hide the previous one (
    static async prompt(InputClass, inputOptions={}, modalOptions={}) {
        let modal;
        const handleInput = (value) => modal?.handleInputChange(value);
        const inputElement = <InputClass {...inputOptions} onChange={handleInput} onSave={handleInput} />;
        modal = this.show({children: [inputElement], ...modalOptions});

        if (!modal) {
            return null;
        }

        return new Promise(resolve => {
            modal.resolvePromise = (result) => {
                delete modal.resolvePromise;
                resolve(result);
            };
        });
    }
}
