import {UI, registerStyle, styleRule} from "stem-core/src/ui/UI";
import {StyleSheet} from "stem-core/src/ui/Style";
import {Card} from "./Card";
import {BlinkInput} from "../../../../blinkpay/ui/Input";
import {BlinkButton} from "../../../../blinkpay/ui/Button";
import {BUTTON_TYPE} from "../../../../blinkpay/Constants";


class EditableCardStyle extends StyleSheet {
    @styleRule
    editButton = {
        fontWeight: 700,
        padding: "0 10px",
        color: "#1C7E8B",
        cursor: "pointer",
        position: "absolute",
        right: 15,
    };

    @styleRule
    updateButton = {
        marginRight: 20,
    };

    @styleRule
    editableField = {
        marginBottom: 10,
    };

    @styleRule
    keyColumn = {
        fontWeight: 500,
        paddingRight: 20,
    };

    @styleRule
    tableCell = {
        padding: 8,
        borderBottom: "1px solid #f2f2f3",
    };
};

@registerStyle(EditableCardStyle)
export class EditableCard extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            editMode: false,
            editableValues: {},
        };
    }

    enableEditMode() {
        const {values, editableFields} = this.options;

        const filtered = {};
        Object.keys(values).filter(x => editableFields.indexOf(x) >= 0).forEach(key => {
            filtered[key] = values[key]
        });

        this.updateOptions({
            editMode: true,
            editableValues: filtered,
        });
    }

    renderNonEditMode() {
        const {values} = this.options;

        return [
            <div className={this.styleSheet.editButton} onClick={() => this.enableEditMode()}>Edit</div>,
            <table>
                <tbody>
                {
                    Object.keys(values).map((key) => {
                        return <tr key={key}>
                            <td className={this.styleSheet.tableCell + this.styleSheet.keyColumn}>{key}</td>
                            <td className={this.styleSheet.tableCell}>{values[key]}</td>
                        </tr>
                    })
                }
                </tbody>
            </table>
        ]
    }

    onUpdateButtonClick() {
        const {onUpdate, editableValues} = this.options;

        onUpdate(editableValues);
        this.updateOptions({editMode: false});
    }

    onCancelButtonClick() {
        this.updateOptions({editMode: false});
    }

    renderEditMode() {
        const {editableFields, values, editableValues} = this.options;

        const form = Object.keys(values).filter(x => editableFields.indexOf(x) >= 0).map((key) => {
            const inputRefKey = key;
            return (
                <div className={this.styleSheet.editableField} key={key}>
                    <b>{key}:</b>
                    <BlinkInput ref={inputRefKey}
                                initialValue={editableValues[key] === null ? "" : editableValues[key]}
                                onChange={() => {
                                    const newValues = {...editableValues};
                                    let value = this[inputRefKey].getValue();
                                    if (value === "true" || value === "false") {
                                        value = (value === "true");
                                    }
                                    newValues[key] = value;
                                    this.updateOptions({editableValues: newValues});
                                }}
                    />
                </div>
            );
        });
        return [
            form,
            <BlinkButton className={this.styleSheet.updateButton}
                         onClick={() => this.onUpdateButtonClick()}
                         label="Update">
            </BlinkButton>,
            <BlinkButton type={BUTTON_TYPE.SECONDARY}
                         onClick={() => this.onCancelButtonClick()}
                         label="Cancel">
            </BlinkButton>
        ]
    }

    render() {
        const {title, children, editMode} = this.options;

        return <Card title={title}>
            {children}
            {editMode ? this.renderEditMode() : this.renderNonEditMode()}
        </Card>
    }
}
