import {registerStyle, styleRule, StyleSheet, UI} from "stem-core/src/ui/UI";
import {MerchantDropdown} from "../../../../blinkpay/widget/ui/MerchantDropdown";
import {CurrencyStore} from "../../../../client/state/CurrencyStore";
import {BlinkInput} from "../../../../blinkpay/ui/Input";
import {CheckboxInput} from "../../../../stem-core/src/ui/input/Input";
import {LegalEntityStore} from "../../../../client/state/LegalEntityStore";

export class CreateMerchantModalStyle extends StyleSheet {
    @styleRule
    currency = {
        width: "100%",
        marginBottom: 8,
    };

    @styleRule
    checkboxSettingsContainer = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    };
}

@registerStyle(CreateMerchantModalStyle)
export class CreateMerchantForm extends UI.Primitive("form") {
    inputRefs = {};

    validateInputs() {
        let isValid = true;

        for (const [key, fieldInput] of Object.entries(this.inputRefs)) {
            fieldInput.validate();
            isValid = isValid && fieldInput.isValid();
        }

        return isValid;
    }

    getInputValues() {
        return {
            legalEntityId: LegalEntityStore.find(legalEntity => legalEntity.name === this.legalEntityDropdown.getValue()).id,
            name: this.inputRefs.nameInput.getValue(),
            alias: this.inputRefs.aliasInput.getValue(),
            url: this.inputRefs.urlInput.getValue(),
            cardStatementName: this.inputRefs.cardStatementInput.getValue(),
            wideLogoUrl: this.inputRefs.logoUrlInput.getValue(),
            isFake: this.isFake.getValue(),
            allowPurchases: this.allowPurchases.getValue(),
            allowSubscriptions: this.allowSubscriptions.getValue(),
            allowDonations: this.allowDonations.getValue(),
            currencyId: CurrencyStore.find(currency => currency.name === this.currencyDropdown.getValue()).id
        }
    }

    render() {
        const {styleSheet} = this;

        return [
            <MerchantDropdown ref="legalEntityDropdown"
                              className={styleSheet.currency}
                              label={"Legal entity"}
                              options={LegalEntityStore.all().map(legalEntity => legalEntity.name)}
                              validators={[{condition: () => !this.legalEntityDropdown.isEmpty()}]}
            />,
            <BlinkInput ref={{parent: this.inputRefs, name: "nameInput"}}
                        label={"Name"}
                        inputAttributes={{placeholder: "Name"}}
                        validators={[{condition: () => !this.inputRefs.nameInput.isEmpty()}]}
            />,
            <BlinkInput ref={{parent: this.inputRefs, name: "aliasInput"}}
                        label={"Alias"}
                        inputAttributes={{placeholder: "Alias"}}
                        validators={[{condition: () => !this.inputRefs.aliasInput.isEmpty()}]}
            />,
            <BlinkInput ref={{parent: this.inputRefs, name: "urlInput"}}
                        label={"URL"}
                        inputAttributes={{placeholder: "URL"}}
                        validators={[{condition: () => !this.inputRefs.urlInput.isEmpty()}]}
            />,
            <BlinkInput ref={{parent: this.inputRefs, name: "cardStatementInput"}}
                        label={"Card Statement Name"}
                        inputAttributes={{placeholder: "Card Statement Name"}}
                        validators={[{condition: () => !this.inputRefs.cardStatementInput.isEmpty()}]}
            />,
            <BlinkInput ref={{parent: this.inputRefs, name: "logoUrlInput"}}
                        label={"Wide logo URL"}
                        inputAttributes={{placeholder: "Wide logo URL"}}
                        validators={[{condition: () => !this.inputRefs.logoUrlInput.isEmpty()}]}
            />,
            <MerchantDropdown ref="currencyDropdown"
                              className={styleSheet.currency}
                              label={"Currency"}
                              options={CurrencyStore.all().map(currency => currency.name)}
                              validators={[{condition: () => !this.currencyDropdown.isEmpty()}]}
            />,
            <div>
                <CheckboxInput ref="isFake" checked={false}/>
                <span>Is fake</span>
            </div>,
            <div className={styleSheet.checkboxSettingsContainer}>
                <div>
                    <CheckboxInput ref="allowPurchases" />
                    <span>Allow purchases</span>
                </div>
                <div>
                    <CheckboxInput ref="allowSubscriptions" />
                    <span>Allow subscriptions</span>
                </div>
                <div>
                    <CheckboxInput ref="allowDonations" />
                    <span>Allow donations</span>
                </div>
            </div>
        ];
    }
}
