import {UI} from "../../../../stem-core/src/ui/UIBase";
import {EndpointPaginator} from "../../../../client/state/EndpointPaginator";
import {apiAdminCreatePolicy, PolicyTermsStore, PolicyType} from "../../../../client/state/PolicyTermsStore";
import {SimpleTable} from "../../../../dashboard/ui/SimpleTable";
import {Button} from "../../../../stem-core/src/ui/button/Button";
import {Select} from "../../../../stem-core/src/ui/input/Input";
import {CodeEditor} from "../../../../stem-core/src/ui/CodeEditor";
import {autoredraw} from "../../../../stem-core/src/decorators/AutoRedraw";
import {ConfirmationModal} from "../../../../blinkpay/ui/ConfirmationModal";
import {RenderPolicyHTML} from "../../../../blinkpay/website/unauthenticated/PolicyPage";
import {Panel} from "../../../../stem-core/src/ui/UIPrimitives";
import {TopLevelTabArea} from "../../../../dashboard/common/theme/TabAreaStyle";
import {DashboardModal} from "../../../../dashboard/common/modals/DashboardModal";

class PolicyTermsPreviewModal extends DashboardModal {
    static preview(html) {
        return this.prompt({html: html, fillScreen: true});
    }

    render() {
        const {html} = this.options;
        return [
            RenderPolicyHTML(html),
        ]
    }
}


class CreatePolicyTerms extends UI.Element {
    async createPolicy() {
        const request = {
            type: this.policyTypeInput.getValue(),
            htmlTemplate: this.htmlInput.getValue(),
        }
        const confirm = await ConfirmationModal.prompt({
            title: "New policy",
            description: `Are you sure you want to update the policy for ${request.type} with ${request.htmlTemplate.length} bytes?`,
        })
        const response = await apiAdminCreatePolicy(request);
    }

    preview() {
        const html = this.htmlInput.getValue();
        PolicyTermsPreviewModal.preview(html);
    }

    render() {
        return [
            <div>
                <Select options={PolicyType.all()} ref="policyTypeInput"/>
                <Button onClick={() => this.preview()}>Preview</Button>
                <Button onClick={() => this.createPolicy()}>Create policy</Button>
            </div>,
            <CodeEditor style={{height: 600, width: "100%"}} aceMode="html" ref="htmlInput"/>
        ]
    }
}


@autoredraw(PolicyTermsStore)
export class CompliancePage extends UI.Element {
    paginator = new EndpointPaginator(PolicyTermsStore, "/compliance/policy_list/");

    render() {
        const columns = [
            ["ID", policy => policy.id],
            ["Type", policy => policy.type],
            ["Created At", policy => policy.createdAt],
            ["Size", policy => policy.htmlTemplate.length],
            ["", policy => <Button onClick={() => PolicyTermsPreviewModal.preview(policy.htmlTemplate)}>Preview</Button>]
        ];

        return [
            <TopLevelTabArea>
                <Panel title="Policy Templates">
                    <SimpleTable paginator={this.paginator} columns={columns}/>,
                </Panel>
                <CreatePolicyTerms title="New Policy Template" />
            </TopLevelTabArea>
        ];
    }
}
