import {Dispatchable} from "../../stem-core/src/base/Dispatcher";
import {LocalStorageMap} from "../../stem-core/src/base/StorageMap";
import {Timezone} from "./Timezone";
import {field} from "../../client/state/misc/StoreField";

class DashboardSettings extends Dispatchable {
    static storage = new LocalStorageMap("DashboardSettings");
    static defaultValues = {
        queryTimezone: Timezone.LOCAL,
        displayTimezone: Timezone.LOCAL,
        dateFormat: "MMM DD, YYYY, h:mm AA",
        theme: "Light",
    }

    @field(Timezone) queryTimezone;
    @field(Timezone) displayTimezone;
    @field(String) dateFormat;
    @field(String) theme;

    constructor() {
        super();
        const storage = this.constructor.storage;
        for (const [key, value] of Object.entries(this.constructor.defaultValues)) {
            this[key] = storage.get(key, value);
        }
        storage.addChangeListener((event) => {
            const {key, newValue} = event;
            this[key] = newValue;
            event.value = this[key];
            this.rebuildFormatters();
            this.dispatch("update", event);
        });
        this.rebuildFormatters();
    }

    rebuildFormatters() {
        this.displayDateFormatter = Intl.DateTimeFormat("en-US", {
            timeZone: this.displayTimezone.getTimezoneName(),
            hourCycle: "h23",
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        });
    }
}

export const dashboardSettings = new Proxy(new DashboardSettings(), {
    get: (settings, key, receiver) => {
        return settings[key];
    },
    set: (settings, key, value) => {
        if (value != null) {
            settings[key] = value;
            settings.constructor.storage.set(key, settings[key].valueOf()); // Use valueOf() on serialization
        } else {
            // We're deleting the value
            settings.constructor.storage.delete(key);
            settings[key] = settings.constructor.defaultValues;
        }
        settings.rebuildFormatters();
        return settings[key];
    }
});


// This might be move somewhere else in the future
export function DashboardDefaultDateFormatter(date, {dateFormatter = dashboardSettings.displayDateFormatter, includeHour = true, includeSecond=false} = {}) {
    const parts = dateFormatter.formatToParts(date);

    let obj = {};
    for (const p of parts) {
        obj[p.type] = p.value;
    }

    let str = `${obj.month} ${obj.day}`;
    if (obj.year != new Date().getFullYear()) {
        str += `, ${obj.year}`;
    }
    str += `, ${obj.hour}:${obj.minute}`;

    return str;
}
