import {UI} from "../stem-core/src/ui/UIBase";
import {MakeSVGIcon} from "../blinkpay/SVGElements";


export const InfoIcon = MakeSVGIcon(({size, color}) => `<svg height="${size}" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-445.000000, -244.000000)" fill="${color}" fill-rule="nonzero">
            <g transform="translate(369.000000, 90.000000)">
                <g transform="translate(0.000000, 142.000000)">
                    <g transform="translate(30.000000, 10.000000)">
                        <g transform="translate(45.000000, 1.000000)">
                            <path d="M7,1.16666667 C3.78,1.16666667 1.16666667,3.78 1.16666667,7 C1.16666667,10.22 3.78,12.8333333 7,12.8333333 C10.22,12.8333333 12.8333333,10.22 12.8333333,7 C12.8333333,3.78 10.22,1.16666667 7,1.16666667 Z M7.58333333,9.91666667 L6.41666667,9.91666667 L6.41666667,6.41666667 L7.58333333,6.41666667 L7.58333333,9.91666667 Z M7.58333333,5.25 L6.41666667,5.25 L6.41666667,4.08333333 L7.58333333,4.08333333 L7.58333333,5.25 Z"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>`);

export const LongRightArrowIcon = MakeSVGIcon(({size, color}) => `<svg height="${size}" viewBox="0 0 12 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M11.8081941,4.5329724 L7.47457176,0.191931827 C7.35086547,0.0680165742 7.1859888,0 7.01018539,0 C6.83418686,0 6.66940775,0.0681142991 6.54570146,0.191931827 L6.15224133,0.586154327 C6.02863261,0.70987413 5.96053562,0.875127042 5.96053562,1.05132515 C5.96053562,1.22742553 6.02863261,1.39824877 6.15224133,1.52196857 L8.68041535,4.05998358 L0.648287272,4.05998358 C0.286143953,4.05998358 0,4.34397232 0,4.70682511 L0,5.26415057 C0,5.62700336 0.286143953,5.93962552 0.648287272,5.93962552 L8.70909804,5.93962552 L6.15233889,8.49181065 C6.02873017,8.6157259 5.96063318,8.77648346 5.96063318,8.95268157 C5.96063318,9.12868423 6.02873017,9.29178719 6.15233889,9.41560472 L6.54579902,9.8085568 C6.66950531,9.93247205 6.83428442,10 7.01028295,10 C7.18608636,10 7.35096303,9.93159253 7.47466932,9.80767727 L11.8082917,5.46673442 C11.9322906,5.34242827 12.0004852,5.17649128 12,5.00009772 C12.0003876,4.82311782 11.9322906,4.65708311 11.8081941,4.5329724 Z" fill="${color}" fill-rule="nonzero"></path>
    </g>
</svg>`);

export const UserAuthenticatedAnalyticsIcon = MakeSVGIcon(({size, color}) => `<svg height="${size}" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M12.8315385,9.77084615 C14.0493077,8.88953846 14.8461538,7.46061538 14.8461538,5.84615385 C14.8461538,3.17453846 12.6723077,1 10,1 C7.32769231,1 5.15384615,3.17453846 5.15384615,5.84615385 C5.15384615,7.46061538 5.95069231,8.88953846 7.16846154,9.77084615 C3.59061538,10.9609231 1,14.3352308 1,18.3076923 C1,18.6905385 1.31015385,19 1.69230769,19 L18.3076923,19 C18.6898462,19 19,18.6905385 19,18.3076923 C19,14.3352308 16.4093846,10.9609231 12.8315385,9.77084615 Z M6.53846154,5.84615385 C6.53846154,3.93746154 8.09130769,2.38461538 10,2.38461538 C11.9086923,2.38461538 13.4615385,3.93746154 13.4615385,5.84615385 C13.4615385,7.75484615 11.9086923,9.30769231 10,9.30769231 C8.09130769,9.30769231 6.53846154,7.75484615 6.53846154,5.84615385 Z M2.41576923,17.6153846 C2.76676923,13.7391538 6.03376923,10.6923077 10,10.6923077 C13.9662308,10.6923077 17.2332308,13.7391538 17.5842308,17.6153846 L2.41576923,17.6153846 Z" id="Shape-Copy" stroke="#FFFFFF" stroke-width="0.2" fill="${color}" fill-rule="nonzero"></path>
    </g>
</svg>`, {
    size: 20,
});

export const UserActionAnalyticsIcon = MakeSVGIcon(({color, size}) => `<svg height="${size}" viewBox="0 0 13 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M6.86558045,18.98778 C10.0994705,18.98778 12.7311609,16.3560896 12.7311609,13.1221996 L12.7311609,6.86558045 C12.7311609,3.63169043 10.0994705,1 6.86558045,1 C3.63169043,1 1,3.63169043 1,6.86558045 L1,13.1221996 C1,16.3560896 3.63169043,18.98778 6.86558045,18.98778 Z M7.25661914,1.80162933 C9.90395112,2.00496945 11.9490835,4.19087576 11.9490835,6.86558045 L11.9490835,8.82077393 L7.25661914,8.82077393 L7.25661914,7.58118126 C7.71804481,7.41694501 8.03869654,6.97507128 8.03869654,6.47454175 L8.03869654,4.12830957 C8.03869654,3.62778004 7.71804481,3.18590631 7.25661914,3.02167006 L7.25661914,1.80162933 Z M6.86558045,3.73727088 C7.08065173,3.73727088 7.25661914,3.91323829 7.25661914,4.12830957 L7.25661914,6.47454175 C7.25661914,6.68961303 7.08065173,6.86558045 6.86558045,6.86558045 C6.65050916,6.86558045 6.47454175,6.68961303 6.47454175,6.47454175 L6.47454175,4.12830957 C6.47454175,3.91323829 6.65050916,3.73727088 6.86558045,3.73727088 Z M1.78207739,6.86558045 C1.78207739,4.19087576 3.82720978,2.00496945 6.47454175,1.80162933 L6.47454175,3.02167006 C6.01311609,3.18590631 5.69246436,3.62778004 5.69246436,4.12830957 L5.69246436,6.47454175 C5.69246436,6.97507128 6.01311609,7.41694501 6.47454175,7.58118126 L6.47454175,8.82077393 L1.78207739,8.82077393 L1.78207739,6.86558045 Z M1.78207739,9.60285132 L11.9490835,9.60285132 L11.9490835,13.1221996 C11.9490835,15.925947 9.6693279,18.2057026 6.86558045,18.2057026 C4.06183299,18.2057026 1.78207739,15.925947 1.78207739,13.1221996 L1.78207739,9.60285132 Z" id="Shape-Copy" stroke="#0080FF" stroke-width="0.5" fill="${color}" fill-rule="nonzero"></path>
    </g>
</svg>`, {
    size: 20
});

export const FilterFunnelSVG = MakeSVGIcon(({color, size}) => `<svg width="${size}" viewBox="0 0 88 65" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" stroke-width="1" fill="${color}" fill-rule="evenodd">
        <g transform="translate(-1075.000000, -758.000000)" fill="${color}">
            <g transform="translate(180.000000, 625.000000)">
                <g transform="translate(780.000000, 88.000000)">
                    <g transform="translate(115.000000, 45.000000)">
                        <g transform="translate(-0.000391, 0.000000)" fill-rule="nonzero">
                            <path d="M56.720125,-4.56863519e-06 L2.62582027,-4.56863519e-06 C1.67174223,-0.0018046952 0.797070274,0.531179726 0.3609375,1.37998445 C-0.081210976,2.24021875 -0.003609375,3.27610945 0.561257774,4.06175 L20.3791328,31.9790625 C20.38575,31.9886875 20.3929688,31.997711 20.399586,32.007336 C21.1196563,32.979461 21.5088672,34.1567188 21.5112735,35.3670625 L21.5112735,58.1277813 C21.5070625,58.8021328 21.77175,59.449414 22.2469845,59.9276563 C22.7222188,60.4058985 23.368297,60.6741953 24.042047,60.6741953 C24.3849375,60.6741953 24.7236172,60.6056172 25.040039,60.4744765 L36.176164,56.228047 C37.1741563,55.9236563 37.8364765,54.982211 37.8364765,53.8482655 L37.8364765,35.3670625 C37.8382813,34.1573203 38.2280938,32.979461 38.9475625,32.007336 C38.9541797,31.997711 38.9613985,31.9886875 38.9680155,31.9790625 L58.785289,4.06054695 C59.3501563,3.27550777 59.4277578,2.24021875 58.9856095,1.37998445 C58.5494765,0.531179726 57.6748047,-0.0018046952 56.720125,-4.56863519e-06 Z M36.4643125,30.182797 C35.3574375,31.6848985 34.7588828,33.5010155 34.7552735,35.3670625 L34.7552735,53.4740938 L24.5912735,57.3493595 L24.5912735,35.3670625 C24.587664,33.5010155 23.9891095,31.6848985 22.8816328,30.182797 L3.64185945,3.08 L55.7046875,3.08 L36.4643125,30.182797 Z" id="Shape"></path>
                        </g>
                        <polygon points="23 47.6856483 36 47.6856483 36 55.7131996 23.8754368 57.6856483"></polygon>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>`, {
    size: 20
});


export const GearIcon = MakeSVGIcon(({color, size}) => `<svg width="${size}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<path fill="${color}" d="M500.633,211.454l-58.729-14.443c-3.53-11.133-8.071-21.929-13.55-32.256c8.818-14.678,27.349-45.571,27.349-45.571
c3.545-5.903,2.607-13.462-2.256-18.325l-42.422-42.422c-4.863-4.878-12.407-5.815-18.325-2.256L347.055,83.53
c-10.269-5.435-21.006-9.932-32.065-13.433l-14.443-58.729C298.876,4.688,292.885,0,286,0h-60
c-6.885,0-12.891,4.688-14.546,11.367c0,0-10.005,40.99-14.429,58.715c-11.792,3.735-23.188,8.584-34.043,14.502l-47.329-28.403
c-5.918-3.516-13.447-2.607-18.325,2.256l-42.422,42.422c-4.863,4.863-5.801,12.422-2.256,18.325l29.268,48.882
c-4.717,9.302-8.672,18.984-11.821,28.901l-58.729,14.487C4.688,213.124,0,219.115,0,226v60c0,6.885,4.688,12.891,11.367,14.546
l58.744,14.443c3.56,11.294,8.188,22.266,13.799,32.798l-26.191,43.652c-3.545,5.903-2.607,13.462,2.256,18.325l42.422,42.422
c4.849,4.849,12.407,5.771,18.325,2.256c0,0,29.37-17.607,43.755-26.221c10.415,5.552,21.313,10.137,32.549,13.696l14.429,58.715
C213.109,507.313,219.115,512,226,512h60c6.885,0,12.876-4.688,14.546-11.367l14.429-58.715
c11.558-3.662,22.69-8.394,33.281-14.136c14.78,8.862,44.443,26.66,44.443,26.66c5.903,3.53,13.462,2.622,18.325-2.256
l42.422-42.422c4.863-4.863,5.801-12.422,2.256-18.325l-26.968-44.927c5.317-10.093,9.727-20.654,13.169-31.523l58.729-14.443
C507.313,298.876,512,292.885,512,286v-60C512,219.115,507.313,213.124,500.633,211.454z M256,361c-57.891,0-105-47.109-105-105
s47.109-105,105-105s105,47.109,105,105S313.891,361,256,361z"/>
</svg>`, {size: 20})
