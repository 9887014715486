import {MakeStore, StoreObject} from "../../../stem-core/src/state/Store";
import {field} from "../misc/StoreField";
import {Money} from "../misc/Money";


class MerchantRevenue extends StoreObject {
    @field("Currency") currency;
    @field(Money) outstandingBalance;
    @field(Money) allTimeRevenue;
    @field(Money) last24hRevenue;
    @field(Money) last30dRevenue;
}

const MerchantRevenueStore = MakeStore("MerchantRevenue", MerchantRevenue);

export {MerchantRevenueStore};
