import {UI} from "../../../../stem-core/src/ui/UIBase";
import {Select} from "../../../../stem-core/src/ui/input/Input";
import {MerchantStore} from "../../../../client/state/MerchantStore";
import {getQueryParam} from "../../../../blinkpay/UtilsLib";
import {Router} from "../../../../stem-core/src/ui/Router";
import {BlinkInputField} from "../../../../dashboard/common/Input";
import {EmailTemplateEditor} from "../../../../dashboard/pages/emailing/EmailTemplateEditor";
import {
    apiCreateEmailTemplate,
    EmailTemplateStore,
    EmailTemplateType
} from "../../../../client/state/EmailTemplateStore";
import {Button} from "../../../../stem-core/src/ui/button/Button";


export class EmailTemplateCreator extends UI.Element {
    async createEmailTemplate() {
        const {merchant, emailTemplateType} = this.options;
        const request = {
            type: emailTemplateType,
            merchantId: merchant.id,
        }
        await apiCreateEmailTemplate(request);
        this.dispatch("createTemplate");
    }

    addCreateListener(listener) {
        return this.addListener("createTemplate", listener);
    }

    render() {
        return [
            <div>
                No email template is registered, the default one is being used.
            </div>,
            <Button onClick={() => this.createEmailTemplate()}>
                Create from default
            </Button>
        ]
    }
}


export class EmailTemplatesPage extends UI.Element {
    DEFAULT_TEMPLATE_TYPE = "successful_donation"; // TODO: use an enum
    DEFAULT_MERCHANT_ID = "default";

    changeUrl(templateType, merchantId) {
        Router.changeURL(`/email-templates/?templateType=${templateType}&merchantId=${merchantId}`,
            {forceElementUpdate:true});
        this.redraw();
    }

    render() {
        const emailTemplateType = getQueryParam("templateType") || this.DEFAULT_TEMPLATE_TYPE;
        const merchantId = getQueryParam("merchantId") || this.DEFAULT_MERCHANT_ID;
        const merchant = MerchantStore.get(merchantId);
        const merchantList = [this.DEFAULT_MERCHANT_ID, ...MerchantStore.all()];
        const emailTemplate = EmailTemplateStore.find(emailTemplate => {
            return emailTemplate.merchantId == merchantId && emailTemplate.type == emailTemplateType;
        })

        return [
            <div>
                <BlinkInputField label="Merchant">
                    <Select
                        selected={merchant || this.DEFAULT_MERCHANT_ID}
                        options={merchantList}
                        onChange={(selector) => {
                            const merchant = selector.getValue();
                            this.changeUrl(emailTemplateType, merchant.id || merchant); // Either we have a merchant or DEFAULT_MERCHANT_ID
                        }}/>

                </BlinkInputField>
                <BlinkInputField label="Email type">
                    <Select
                        options={EmailTemplateType.all()}
                        selected={emailTemplateType}
                        onChange={(selector) => this.changeUrl(selector.getValue(), merchantId)}
                    />
                </BlinkInputField>
            </div>,
            <hr/>,
            emailTemplate ? <EmailTemplateEditor
                emailTemplate={emailTemplate}
            /> : <EmailTemplateCreator
                emailTemplateType={emailTemplateType}
                merchant={merchant}
                onCreate={() => this.redraw()}
            />
        ]
    }
}
