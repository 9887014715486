import {UI} from "../../../../../stem-core/src/ui/UIBase";
import {StyleSheet} from "../../../../../stem-core/src/ui/Style";
import {StemDate} from "../../../../../stem-core/src/time/Date";
import {styleRule} from "../../../../../stem-core/src/decorators/Style";
import {registerStyle} from "../../../../../stem-core/src/ui/style/Theme";
import {daysInWeek, rangeDayCalendarPadding, weekStartingDayIndex} from "../DateRangePickerConstants";
import {queueMicrotaskWrapper} from "../../../../../blinkpay/AsyncAggregateDispatcher";
import {DashboardBaseInput} from "../../../../../core/ui/BaseInput";

const referenceHours = 17;

const daysRowsCount = 6;

class DateCalendarMonthInputInterfaceStyle extends StyleSheet {
    @styleRule
    dayLabel = {
        color: this.themeProps.MERCHANT_2,
        textAlign: "center",
        paddingBottom: 10,
    };

    @styleRule
    month = {
        display: "flex",
        userSelect: "none",
        padding: `0 ${rangeDayCalendarPadding}px`
    };
}

@registerStyle(DateCalendarMonthInputInterfaceStyle)
export class DateCalendarMonthInputInterface extends DashboardBaseInput {
    hoverDate = null;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            monthDate: null,
            minDate: null,
            maxDate: null,
        }
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.month);
    }

    addDay(date, count) {
        date.setHours(referenceHours);
        date.setDate(date.getDate() + count);
        return date;
    }

    render() {
        let date = new StemDate(this.options.monthDate);
        date.setDate(1);
        for (let i = 0; i <= 7; i += 1) {
            if (date.getDay() !== weekStartingDayIndex) {
                date = this.addDay(date, -1);
            } else {
                break;
            }
        }

        const elements = [];
        for (let i = 0; i < daysInWeek; i += 1) {
            elements.push(this.renderDayColumn(date));
            date = this.addDay(date, 1);
        }

        return elements;
    }

    renderDayElement(date) {
        throw "Implement";
    }

    renderDayColumn(date) {
        const {styleSheet} = this;
        const elements = [];
        const inputDate = new StemDate(date);
        elements.push(<div className={styleSheet.dayLabel}>{inputDate.format("dd")}</div>);

        for (let i = 0; i < daysRowsCount; i += 1) {
            elements.push(this.renderDayElement(new StemDate(inputDate)));
            this.addDay(inputDate, 7);
        }

        return <div className={styleSheet.column}>{elements}</div>;
    }

    enqueueRedraw() {
        if (this.redrawQueued) {
            return;
        }
        this.redrawQueued = true;
        queueMicrotaskWrapper(() => {
            this.redrawQueued = false;
            this.redraw();
        })
    }

    onDateHover(date) {
        this.hoverDate = date;
        this.enqueueRedraw();
    }

    onDateSelection(date) {
        throw "Implement";
    }
}
