import {UI} from "../../../../stem-core/src/ui/UIBase";
import {UserProfileStore} from "../../../../client/state/UserProfileStore";
import {EndpointPaginator} from "../../../../client/state/EndpointPaginator";
import {SimpleTable} from "../../../../dashboard/ui/SimpleTable";
import {Select, TextInput} from "../../../../stem-core/src/ui/input/Input";
import {Link} from "../../../../stem-core/src/ui/UIPrimitives";
import {DashboardTitle} from "../../../../dashboard/common/DashboardTitle";
import {UserType} from "../../../../client/state/UserProfileStore";


class UserFilter extends UI.Element {
    render() {
        const updateFilter = () => this.updateFilter();

        return [
            <Select ref="typeSelect" options={["Any", ...UserType.all()]}
                        style={{margin: 10}}
                        onChange={updateFilter}/>,
            <TextInput ref="userSearchInput" style={{height: "2.03em", margin: 10, verticalAlign: "middle"}}
                       placeholder="Search by email or name"
                       onChange={updateFilter} />

        ];
    }

    updateFilter() {
        const {paginator} = this.options;
        const userType = this.typeSelect.getValue();
        const filters = {
            nameOrEmailSearch: this.userSearchInput.getValue() || null,
            userType: (userType instanceof UserType) ? userType : null,
        }

        paginator.updateFilter(filters);
    }
}

export class UsersPage extends UI.Element {
    paginator = new EndpointPaginator(UserProfileStore, "admin/users/list", {
        order: "-createdAt",
    });

    render() {
        const columns = [
            ["Email", user => <Link href={`/users/${user.id}`}>{user.getEmail()}</Link>],
            ["Name", user => user.getName()],
            ["Type", user => user.userType],
            ["Date Joined", user => user.createdAt],
            ["Last Login", user => user.lastLogin],
            ["Active", user => user.isActive],
            ["Referral merchant", user => user.referralMerchant],
        ];

        return [
            <DashboardTitle title={"Users"}/>,
            <UserFilter paginator={this.paginator}/>,
            <SimpleTable paginator={this.paginator} columns={columns}/>,
        ];
    }
}
