import {UI} from "../../../../stem-core/src/ui/UIBase";
import {UserSessionsList} from "./user-sessions/UserSessionsList"
import {UserPaymentMethodList} from "./payment-methods/PaymentMethodList";
import {UserWalletCreditList} from "./payments/CreditList";
import {UserDepositList} from "./payments/DepositList";
import {UserPaymentList} from "./payments/PaymentList";
import {UserSubscriptionList} from "./subscriptions/SubscriptionList";
import {UserPreferences} from "./preferences/PreferencesDetail";
import {LoadingSpinner} from "../../../../blinkpay/ui/LoadingSpinner";
import {UserWallet} from "./wallet/UserWallet";
import {UserProfile} from "./profile/UserProfile";
import {apiAdminFetchUser} from "../../../../client/state/AdminUserStore";


export class AdminUserPanel extends UI.Element {
    async fetchUser() {
        const userId = this.options.args[0]; // TODO: our route passing suck just differently than the react one, simplify
        const user = await apiAdminFetchUser(userId);
        this.updateOptions({user});
    }

    render() {
        const {user} = this.options;
        if (!user) {
            this.fetchUser();
            return <LoadingSpinner/>;
        }

        return <div>
            <UserProfile user={user}/>
            <UserPreferences user={user}/>
            <UserWallet user={user}/>
            <UserPaymentMethodList user={user}/>
            <UserPaymentList user={user}/>
            <UserDepositList user={user}/>
            <UserWalletCreditList user={user}/>
            <UserSubscriptionList user={user}/>
            <UserSessionsList user={user}/>
        </div>
    }
}
