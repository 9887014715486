import {UI, registerStyle, styleRule} from "stem-core/src/ui/UI";
import {StyleSheet} from "stem-core/src/ui/Style";

// TODO possibly deprecate this
class CardStyle extends StyleSheet {
    @styleRule
    title = {
        fontSize: 18,
        fontWeight: "normal",
        marginTop: 16,
    };

    @styleRule
    hr = {
        marginTop: 0,
        marginBottom: 8,
    }
}

// TODO rename to something better than card, like SubSection
@registerStyle(CardStyle)
export class Card extends UI.Element {
    render() {
        const {title, children} = this.options;

        return [
            <div className={this.styleSheet.title}>
                {title}
            </div>,
            // <hr className={this.styleSheet.hr}/>,
            ...children
        ];
    }
}
