// TODO: this file is not best placed here

import {StemDate} from "../../../../stem-core/src/time/Date";
import {UI} from "../../../../stem-core/src/ui/UIBase";
import {SortableTable} from "../../../../stem-core/src/ui/table/SortableTable";
import {DateRangeInput, DateRangeInterval} from "../../../../dashboard/ui/input/DateRangeInput";
import {apiConnection} from "../../../../client/connection/BlinkApiConnection";
import {PaymentStore} from "../../../../client/state/PaymentStore";
import {Money} from "../../../../client/state/misc/Money";

const PAYMENTS_TABLE_COLUMNS = [{
    headerName: "Timestamp",
    rawValue: (entry) => +(new StemDate(entry.createdAt)),
    value: (entry) => StemDate.format(entry.createdAt, "DD MMM YYYY at hh:mm"),
    headerStyle: {verticalAlign: "middle"},
}, {
    headerName: "Amount",
    rawValue: (entry) => entry.amount,
    value: (entry) => Money.format(entry),
    headerStyle: {verticalAlign: "middle"},
},
{
    headerName: "Type",
    rawValue: (entry) => entry.getType(),
    value: (entry) => entry.getType(), // TODO: format
    headerStyle: {verticalAlign: "middle"},
},
{
    headerName: "Merchant",
    rawValue: payment => payment.getMerchant().toString(),
    value: payment => payment.getMerchant(),
    headerStyle: {verticalAlign: "middle"},
}];


// TODO deduplicate code with PaymentList - use paginator
export class LatestPaymentsTable extends UI.Element {
    payments = [];

    getEntries() {
        return this.payments;
    }

    getTotal() {
        let total = 0;
        for (let entry of this.getEntries()) {
            total += entry.amount;
        }
        return total / 1e6;
    }

    filterEntries() {
        const {startDate, endDate} = this.dateRangeFilter.getDateRange();
    }

    getFilterSection() {
        return <div style={{padding: "1em"}}>
            <DateRangeInput ref="dateRangeFilter"
                            style={{width: 350}} // TODO: also pretty nasty that we need to provide with a good width here
                            options={[
                                DateRangeInterval.TODAY,
                                DateRangeInterval.LAST_7_DAYS,
                                DateRangeInterval.YEAR_TO_DATE,
                                new DateRangeInterval("All Time", () => [new StemDate(2019, 0, 1), new StemDate()]),
                             ]} onChange={() => this.filterEntries()}/>
        </div>
    }

    render() {
        // TODO: @branch use a paginator here with the SortableTable
        return [
            <h1>Latest payments {this.getEntries().length}. Total amount: ${this.getTotal()}</h1>,
            this.getFilterSection(),
            <SortableTable entries={this.getEntries()} columns={PAYMENTS_TABLE_COLUMNS} />
        ]
    }

    async apiGetLatestPayments() {
        return apiConnection.get("/admin/payments/get_payments/", {
            pageSize: 100,
        });
    }

    //@onMount
    async refreshData() {
        const response = await this.apiGetLatestPayments();
        this.payments = PaymentStore.loadFromResponse(response);
        this.redraw();
    }

    onMount() {
        this.refreshData();
    }
}
