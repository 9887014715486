import {UI} from "../../stem-core/src/ui/UIBase";
import {styleRule, StyleSheet} from "../../stem-core/src/ui/Style";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";
import {Level} from "../../stem-core/src/ui/Constants";
import {InfoContentPopup} from "./InfoTooltip.jsx";

class DashboardLabelStyle extends StyleSheet {
    @styleRule
    container = {
        display: "inline-block",
        color: "white",
        borderRadius: 4,
        background: this.themeProps.COLOR_SUCCESS,
        padding: "2px 4px",
        margin: 2,
        fontSize: 13,
        fontWeight: "bold",
    }

    @styleRule
    info = {
        background: "#DDD",
        color: "#405050", // TODO use themeProps
    }

    @styleRule
    warning = {
        background: this.themeProps.COLOR_WARNING,
    }

    @styleRule
    error = {
        background: this.themeProps.COLOR_DANGER,
    }
}

@registerStyle(DashboardLabelStyle)
export class DashboardLabel extends UI.Element {
    extraNodeAttributes(attr) {
        const {level, tooltip} = this.options;
        attr.addClass(this.styleSheet.container);
        if (level === Level.INFO) {
            attr.addClass(this.styleSheet.info);
        }
        if (level === Level.WARNING) {
            attr.addClass(this.styleSheet.warning);
        }
        if (level === Level.ERROR) {
            attr.addClass(this.styleSheet.error);
        }
        if (tooltip) {
            attr.setStyle({
                cursor: "help",
            });
        }
    }

    showPopup() {
        const {tooltip} = this.options;
        tooltip && InfoContentPopup.show({
            anchor: this,
            content: tooltip,
        })
    }

    render() {
        const {label} = this.options;
        return label || super.render();
    }

    onMount() {
        for (const eventType of ["mouseenter", "tap"]) {
            this.addNodeListener(eventType, () => this.showPopup());
        }
    }
}
