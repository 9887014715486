import {MakeStore, StoreObject} from "../../../../stem-core/src/state/Store";
import {field} from "../../misc/StoreField";

class CDSRequestInstance extends StoreObject {
    @field("UserProfile") user;
    @field("Merchant") merchant;
    @field("MerchantCDSProduct") product;
    @field(Date) createdAt;
    @field(String) url;
    @field(String) method;
    @field(String) requestPayload;

    @field(Number) responseCode;
    @field(Number) responseElapsedMs;
    @field(String) responsePayload;
    @field(Boolean) responseCdsIsSuccess;
    @field(Boolean) responseSentToOffline;
    @field(String) responseCdsMessage;

    @field(Object) exceptionInfo;

    getRequest() {
        return JSON.parse(this.requestPayload);
    }

    getResponse() {
        return this.responsePayload && JSON.parse(this.responsePayload);
    }

}

export const CDSRequestStore = MakeStore("CDSRequestInstance", CDSRequestInstance);
