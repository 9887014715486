import {UI} from "../../../stem-core/src/ui/UIBase";
import {ChevronDownIcon} from "../../../blinkpay/SVGElements";

export const BlinkMakeIcon = (icon, options) => {
    // TODO: this is common with the Stem implementation, reuse?
    if (!icon) {
        return null;
    }

    if (icon instanceof UI.Element) {
        return icon;
    }

    if (icon.prototype instanceof UI.Element) {
        return new icon(options);
    }

    if (typeof icon === "function") {
        return icon(options);
    }

    // TODO: handle string mappings here

    if (icon == "chevron-down") {
        return <ChevronDownIcon {...options} />;
    }

    console.warn("Invalid icon:", icon);

    // TODO: here we should have a fallback and log a warning
    return <span>Icon {icon}</span>
}