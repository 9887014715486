import {UI} from "../../../../stem-core/src/ui/UIBase";
import {apiClient} from "../../../../client/connection/BlinkApiConnection";
import {Dispatchable} from "../../../../stem-core/src/base/Dispatcher";
import {autoredraw} from "../../../../stem-core/src/decorators/AutoRedraw";
import {CollapsiblePanel} from "../../../../stem-core/src/ui/collapsible/CollapsiblePanel";
import {StemDate} from "../../../../stem-core/src/time/Date";
import {SimpleTable} from "../../../../dashboard/ui/SimpleTable";
import {Money} from "../../../../client/state/misc/Money";
import {CENTER_COLUMN, LEFT_COLUMN, MONEY_COLUMN} from "../../../../dashboard/common/theme/TableStyle";
import {DashboardStaticText} from "../../../../dashboard/common/DashboardStaticText";
import {bytesToSize} from "../../../../blinkpay/Utils";
import {ArrayPaginator} from "../../../../client/state/EndpointPaginator";

class ServerStatisticsManager extends Dispatchable {
    stats = null;
    error = null;

    getStats() {
        return this.stats;
    }

    async update() {
        try {
            // Throttles or other modifiers should happen here
            this.stats = await apiClient.get("/admin/get_server_statistics/");
        } catch (error) {
            console.error(error);
            this.error = error;
        }

        this.dispatch("update");
    }
}

export const serverStatisticsManager = new ServerStatisticsManager();

function RenderDashboardStatsTable(digests) {
    const columns = [
        ["Date", entry => StemDate.format(entry.startDate, " DD MMM"), LEFT_COLUMN],
        ["New users", entry => entry.newUsersCount],
        ["(Social accounts)", entry => entry.socialAccountsCount],
        ["Unvalidated email registrations", entry => entry.failedRegistrationsCount],
        ["CC charges", entry => entry.cardChargesCount],
        ["Amount charged", entry => new Money(entry.cardChargesSum, "usd"), MONEY_COLUMN],
        ["Wallet topup", entry => new Money(entry.walletTopUpsSum, "usd"), MONEY_COLUMN],
        ["Failed charges", entry => entry.failedCardChargesCount],
    ]

    for (let col of columns) {
        if (col.length == 2) {
            col.push(CENTER_COLUMN);
        }
    }

    return <SimpleTable columns={columns} entries={Array.from(digests).reverse()}/>
}

@autoredraw(serverStatisticsManager)
export class APIServerStats extends CollapsiblePanel {
    getDefaultOptions() {
        return {
            title: "Daily digest table",
        }
    }

    render() {
        const {stats, error} = serverStatisticsManager;
        if (error) {
            return <h1>Error in loading server stats: {error.message}</h1>
        }

        if (stats) {
            return RenderDashboardStatsTable(stats.digest);
        }

        return <h1>Loading stats...</h1>
    }

    onMount() {
        serverStatisticsManager.update();
    }
}

@autoredraw(serverStatisticsManager)
export class BackendDatabaseStats extends CollapsiblePanel {
    getDefaultOptions() {
        return {
            title: "Database stats",
        }
    }

    getDatabaseStats(dbName, dbStats) {
        let tableSize = 0;
        for (let table of dbStats.tables) {
            tableSize += table.size;
        }
        const columns = [
            ["Table", table => table.name],
            ["Size on disk", table => bytesToSize(table.size)],
        ]

        return [
            <h1>Database {dbName}</h1>,
            <div>Disk usage: {bytesToSize(tableSize)}</div>,
            <div>Largest tables:</div>,
            <SimpleTable columns={columns} paginator={new ArrayPaginator(dbStats.tables)} />
        ]
    }

    render() {
        const {stats} = serverStatisticsManager;

        if (!stats) {
            return <h1>Loading...</h1>;
        }

        return Object.entries(stats.databases).map(([dbName, dbStats]) => this.getDatabaseStats(dbName, dbStats));
    }
}

@autoredraw(serverStatisticsManager)
export class TaskQueueStats extends CollapsiblePanel {
    getDefaultOptions() {
        return {
            title: "Task queue stats",
        }
    }

    render() {
        const {stats} = serverStatisticsManager;

        if (stats) {
            return [
                <DashboardStaticText type="json" value={stats.redisTasks} />
            ]
        }

        return "Loading...";
    }
}
