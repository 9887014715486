import {styleRule, styleRuleInherit} from "../../../stem-core/src/decorators/Style";
import {Theme} from "../../../stem-core/src/ui/style/Theme";
import {FlatTabArea} from "../../../stem-core/src/ui/tabs/FlatTabArea";
import {Table} from "../../../stem-core/src/ui/table/Table";
import {ButtonStyle} from "../../../stem-core/src/ui/button/ButtonStyle";
import {Button} from "../../../stem-core/src/ui/button/Button";
import {SetMakeIcon} from "../../../stem-core/src/ui/SimpleElements";
import {BlinkMakeIcon} from "./MakeIcon";
import {setDefaultDateFormat, StemDate} from "../../../stem-core/src/time/Date";
import {CollapsiblePanelStyle} from "../../../stem-core/src/ui/collapsible/Style";
import {CollapsiblePanel} from "../../../stem-core/src/ui/collapsible/CollapsiblePanel";
import {enhance} from "../../../stem-core/src/ui/Color";
import {StyleSheet} from "../../../stem-core/src/ui/Style";
import {Money} from "../../../client/state/misc/Money";
import {BlinkTabAreaStyle} from "./TabAreaStyle";
import {BlinkTableStyle} from "./TableStyle";
import {GlobalStyleSheet} from "../../GlobalStyleSheet";
import {initializeTheme as initializeBlinkPayTheme} from "../../../blinkpay/StyleConstants";
import {isFirefox} from "../../../blinkpay/Utils";
import {CodeEditor} from "../../../stem-core/src/ui/CodeEditor";
import {ensure} from "../../../stem-core/src/base/Require";
import {DashboardDefaultDateFormatter} from "../../misc/DashboardSettings";
export * from "./TableStyle";

export class DashboardPageStyle extends StyleSheet {
    @styleRule
    mainPage = {
        boxSizing: "border-box", // TODO: should be default for everything
        width: () => `calc(100% - ${Theme.props.SIDEBAR_WIDTH}px)`,
        background: this.themeProps.COLOR_BACKGROUND,
        position: "relative",
        float: "right",
        minHeight: "100vh", // Leaving it to height will mess up the scroll
        padding: 36,
        paddingTop: 0,
        overflow: "overlay", // So that the scroll bar doesn't shift the page when appearing
    };

    @styleRule
    userEmail = {
        display: "inline-block",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 240,
        fontWeight: "bold",
        cursor: "pointer",
        color: this.themeProps.LINK_COLOR,
    }
}

class BlinkCollapsiblePanelStyle extends CollapsiblePanelStyle {
    @styleRuleInherit
    panel = {
        borderColor: this.themeProps.CARD_BORDER_COLOR,
        borderRadius: this.themeProps.CARD_BORDER_RADIUS,
    }

    @styleRuleInherit
    heading = {
        fontSize: 12,
        fontWeight: 700,
        backgroundColor: this.themeProps.CARD_HEADER_BACKGROUND_COLOR,
        textTransform: "uppercase",
        borderTopLeftRadius: this.themeProps.CARD_BORDER_RADIUS,
        borderTopRightRadius: this.themeProps.CARD_BORDER_RADIUS,
        ":hover": {
            backgroundColor: enhance(this.themeProps.CARD_HEADER_BACKGROUND_COLOR, 0.01),
        }
    }
}


class BlinkButtonStyle extends ButtonStyle {
    @styleRuleInherit
    DEFAULT = {
        transition: ".18s ease",
        margin: "2px 8px",
        cursor: "pointer",
    }
}

CodeEditor.requireAce = function(callback) {
    ensure("/resources/js/ace/ace.js", () => {
        if (!CodeEditor.AceRange) {
            CodeEditor.langToolsSrc = "/resources/js/ace/ext-language_tools.js";
            CodeEditor.AceRange = window.ace.require("ace/range").Range;
        }
        callback && callback();
    });
};


export function registerDashboardStyleSheets() {
    initializeBlinkPayTheme();

    // TODO: cleanup some of these
    Theme.setProperties({
        PAGE_TEXT_COLOR: "#232323",
        PAGE_TEXT_LIGHTER_COLOR: "#6C6C6C",
        INACTIVE_BORDER_COLOR: "#E5EAE9",
        ACTIVE_BORDER_COLOR: "#BBD4D8",
        SIDEBAR_WIDTH: 300,
        SIDEBAR_LINK_DEFAULT_COLOR: "#646464",
        SIDEBAR_LINK_ACTIVE_COLOR: props => props.BUTTON_COLOR, // TODO PRIMARY, deprecate usages
        SIDEBAR_BACKGROUND: "#F7FAFC",
        FIT_CONTENT: `${isFirefox() ? "-moz-" : ""}fit-content`, // TODO: this is duplicated, should it be
        BUTTON_BOX_SHADOW: "0 2px 6px 0 rgba(6, 73, 83, 0.24)",
        BUTTON_PRIMARY_COLOR: "#fff",
        BUTTON_PRIMARY_BACKGROUND_COLOR: "#086472",
        BUTTON_SECONDARY_COLOR: "#086472",
        BUTTON_SECONDARY_BORDER_COLOR: "#BBD4D8",
        BUTTON_SECONDARY_BACKGROUND_COLOR: "#fff",
        BASE_CARD_WIDTH: 480,
        BASE_CARD_MAX_WIDTH: "min(90vw, 900px)",
        TEXT_PRIMARY_COLOR: "#1A2F36",
        TEXT_SECONDARY_COLOR: "#75797F", // TODO - rename to SECONDARY
        CARD_BACKGROUND_COLOR: '#fff',
        CARD_BOX_SHADOW: "0 8px 16px -11px rgba(0,0,0,0.6)", // TODO: deprecate this
        CARD_CONTENT_TEXT_COLOR: "#232323",
        CARD_CONTENT_TEXT_LIGHTER_COLOR: "#6C6C6C",
        DIVIDER_LINE_COLOR: "#DDDDDD",
        DIVIDER_TEXT_COLOR: "#3F3F3F",
        THEAD_BACKGROUND_COLOR: "#F5F7F7", // TODO: these constants should be named starting with TABLE_
        THEAD_TEXT_COLOR: "#086472",
        TABLE_EVEN_LINE_BACKGROUND_COLOR: "#F4F8F8",
        TABLE_BORDER_COLOR_2: "#F0F0F0",
        TABLE_NORMAL_TEXT_COLOR: "#212121",
        TABLE_LIGHTER_TEXT_COLOR: "#6C6C6C",
        TABLE_BOLD_TEXT_COLOR: "#353535",
        TABLE_NO_DATA_TEXT_COLOR: "#909090",
        INPUT_BACKGROUND_COLOR: "#FFF",
        INPUT_LABEL_COLOR: "#3F3F3F",
        INPUT_TEXT_COLOR: "#232323",
        INPUT_ERROR_COLOR: "#E74E17",
        DROPDOWN_LABEL_COLOR: "#909090",
        SUMMARY_ITEM_BORDER_COLOR: "rgba(27,126,140,0.18)",
        CHART_TOOLTIP_BACKGROUND_COLOR: "#fff",
        CHART_TOOLTIP_DROPDOWN_SHADOW: "0 2px 8px 5px rgba(0,0,0,.1), 0 5px 27px -5px rgba(0,0,0,.37)",
        CHART_LINE_COLOR: "#F2F2F2",
        CHART_BAR_BACKGROUND_COLOR_1: "#DEA800",
        CHART_BAR_BACKGROUND_COLOR_2: "#4C837A",
        CHART_BAR_BACKGROUND_COLOR_3: "#0A2B32",
        INPUT_BACKGROUND: "rgba(2, 92, 150, 0.06)",
        INPUT_COLOR: Theme.props.TEAL_2,
        APP_BACKGROUND: "#f7f7f7",
        INFO_TEXT_COLOR: "#909090",
        FILTERS_BOX_SHADOW: "0 8px 16px -11px rgba(0, 0, 0, 0.8)",
        DATE_PICKER_HEADER_BACKGROUND: Theme.props.LANDING_0,
        DATE_PICKER_DAY_LIGHT_GREY: "#E5E5E5",
        DATE_PICKER_DAY_DARK_GREY: "#D4D4D4",
        DATE_PICKER_DAY_LIGHT_GREEN: "rgb(152 187 193)",
        DATE_PICKER_DAY_MEDIUM_GREEN: "#1E727E",
        DATE_PICKER_DAY_DARK_GREEN: "#086472",  // TODO: yeah, great, datepicker specific coloses
        DATE_PICKER_INPUT_ACTIVE_COLOR: Theme.props.WALLET_6,
        DATE_PICKER_INPUT_ACTIVE_BOX_SHADOW: "0 8px 16px -11px rgba(0,0,0,.8)",
        DATE_PICKER_INPUT_INACTIVE_BORDER_COLOR: "#F0F0F0",
        DATE_PICKER_INPUT_LABEL_COLOR: Theme.props.MERCHANT_3,
        DATE_PICKER_INPUT_PICK_A_DATE_COLOR: Theme.props.MERCHANT_2,
        DATE_PICKER_INPUT_COLOR: Theme.props.MERCHANT_3,
        TOOLTIP_BORDER_COLOR: "#E0E0E0",
        TOOLTIP_DELETE_COLOR: "#E74E17",

        COLOR_SECONDARY: "#FFF",

        LINK_COLOR: "#337AB7",
        CARD_BORDER_RADIUS: 4,
        CARD_HEADER_BACKGROUND_COLOR: "#EBF3F3",
        CARD_HEADER_HEIGHT: 40,
        CARD_BORDER_COLOR: "#E5E5E5",

        BUTTON_BORDER_RADIUS: 6,
        BUTTON_COLOR: "#005A64",

        INPUT_ELEMENT_DEFAULT_HEIGHT: 36, // Aiming to have all the filterable input of this height (without label)

        INPUT_BORDER_COLOR: "#E5EAE9",
        INPUT_BORDER_RADIUS: props => props.BUTTON_BORDER_RADIUS,

        BASE_INPUT_BACKGROUND: "white",

        TAB_AREA_BORDER_RADIUS: props => props.CARD_BORDER_RADIUS,

        FLAT_TAB_AREA_UNDERLINE_HEIGHT: 2,
        FLAT_TAB_AREA_TAB_STYLE: props => ({
            backgroundColor: props.CARD_HEADER_BACKGROUND_COLOR,
            fontSize: 12,
            fontWeight: "bold",
            textTransform: "uppercase",

        }),

        RADIO_BUTTON_PADDING: "8.5px 18px",
        RADIO_BUTTON_FLEX: "",
        ACTIVE_BUTTON_FONT_SIZE: 14,
        ACTIVE_BUTTON_INACTIVE_COLOR: props => props.TEXT_PRIMARY_COLOR,
        ACTIVE_BUTTON_INACTIVE_BACKGROUND: "white",
        ACTIVE_BUTTON_INACTIVE_HOVER_BACKGROUND: "#f1f1f1",
        ACTIVE_BUTTON_INACTIVE_BORDER: "1px solid #E5EAE9",  // TODO @cleanup less inline colors
        ACTIVE_BUTTON_ACTIVE_BORDER: "1px solid #005A64",

        CHECKBOX_BORDER_COLOR: Theme.props.WALLET_11,
        CHECKBOX_ENABLED_BACKGROUND_COLOR: Theme.props.WALLET_11,
        CHECKBOX_CHECKMARK_COLOR: Theme.props.WHITE,
        CHECKBOX_SIZE: "1em"
    });

    GlobalStyleSheet.initialize();

    Theme.register(FlatTabArea, BlinkTabAreaStyle);
    Theme.register(Table, BlinkTableStyle);
    Theme.register(Button, BlinkButtonStyle);
    Theme.register(CollapsiblePanel, BlinkCollapsiblePanelStyle);

    SetMakeIcon(BlinkMakeIcon);
    // TODO @Mihai make this PascalCase
    setDefaultDateFormat(DashboardDefaultDateFormatter);

    Money.useFormatter = true;
}
