import {UI} from "../../../../../stem-core/src/ui/UIBase";
import {Card} from "../../ui/Card";
import {SimpleTable} from "../../../../../dashboard/ui/SimpleTable";
import {Messages} from "../../../../../blinkpay/Messages";
import {capitalize} from "../../../../../blinkpay/Utils";


export class UserPaymentMethodList extends UI.Element{
    render() {
        const {user} = this.options;
        const userPaymentMethods = user.getPaymentMethods();
        const columns = [
            ["Id", paymentMethod => paymentMethod.id],
            ["Provider", paymentMethod => paymentMethod.provider],
            ["Card", paymentMethod => {
                return paymentMethod && Messages.usingCard(capitalize(paymentMethod.getBrand() ), paymentMethod.getLastDigits());
            }],
            ["Is Default", obj => obj.isPrimary ? "true" : "false"],
            ["Created at", obj => obj.createdAt],
        ]
        return (
            <Card title="Payment Methods">
                <SimpleTable entries={userPaymentMethods} columns={columns} />
            </Card>
        );
    }
}
