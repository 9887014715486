import {FormContainerPage} from "../../../../blinkpay/website/unauthenticated/slides/FormContainerPage";
import {SOCIAL_APPS} from "../../../../blink-sdk/Constants.js";
import {AuthFlowPanel} from "../../../../blinkpay/website/unauthenticated/slides/AuthFlowPanel.jsx";


export const AdminLoginPage = FormContainerPage(AuthFlowPanel, {
    onAuth: () => {
        window.location.href = "/";
    },
    showFooter: false,
    useSecureCode: true,
    createUserAutomatically: false,
    externalAuthProviders: [SOCIAL_APPS.Google],
})
