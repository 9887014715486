import {UI} from "../../../../stem-core/src/ui/UIBase";
import {AnalyticsEventStore} from "../../../../client/state/merchant/AnalyticsEventStore";
import {EndpointPaginator} from "../../../../client/state/EndpointPaginator";
import {DashboardTitle} from "../../../../dashboard/common/DashboardTitle";
import {SimpleTable} from "../../../../dashboard/ui/SimpleTable";
import {CollapsibleTable} from "../../../../stem-core/src/ui/table/CollapsibleTable";
import {DashboardStaticText} from "../../../../dashboard/common/DashboardStaticText";
import {BlinkInputField} from "../../../../dashboard/common/Input";
import {Select} from "../../../../stem-core/src/ui/input/Input";
import {AnalyticsEventType} from "../../../../blink-sdk/utils/AnalyticsClient";
import {BaseEnum} from "../../../../client/state/misc/BaseEnum";
import {
    DateRangeInput, DateRangeInterval,
} from "../../../../dashboard/ui/input/DateRangeInput.jsx";

export class AnalyticsEventsPage extends UI.Element {
    paginator = new EndpointPaginator(AnalyticsEventStore, "/admin/analytics/list/");

    applyFilters() {
        let eventType = this.eventTypeInput.getValue();
        const filters = {
            types: eventType !== "Any" ? [eventType.key] : null,
            createdAt: this.createdAtDateRangeInput.valueOf(),
        };
        this.paginator.updateFilter(filters);
    }

    render() {
        const columns = [
            ["Type", event => event.type],
            ["Timestamp", event => event.createdAt],
            ["Session time (sec.)", event => event.localSessionTime.toFixed(3)],
            ["IP", event => event.ip],
        ];

        const simpleTableOptions = {
            tableClass: CollapsibleTable,
            tableOptions: {
                renderCollapsible: (event) => <DashboardStaticText type="json" value={event.payload} />,
            }
        }

        const EVENT_TYPES = Object.entries(AnalyticsEventType).map(([key, eventType]) => {
            return {
                ...eventType,
                toString: () => BaseEnum.defaultName(key),
            }
        });

        const DATE_RANGE_OPTIONS = [
            DateRangeInterval.LAST_24_HOURS,
            DateRangeInterval.LAST_7_DAYS,
            DateRangeInterval.LAST_30_DAYS,
            DateRangeInterval.LAST_3_MONTHS,
            DateRangeInterval.ALL_TIME,
        ];

        return [
            <DashboardTitle title={"Analytics Events"}/>,
            <div>
                <BlinkInputField label="Event type">
                    <Select
                        ref="eventTypeInput"
                        options={["Any", ...EVENT_TYPES]}
                        onChange={() => this.applyFilters()}/>
                </BlinkInputField>
                <BlinkInputField label="Created at">
                    <DateRangeInput
                        ref="createdAtDateRangeInput"
                        style={{maxWidth: 350, display: "inline-block"}}
                        options={DATE_RANGE_OPTIONS}
                        selectedInterval={DateRangeInterval.LAST_24_HOURS}
                        onChange={() => this.applyFilters()}
                    />
                </BlinkInputField>
            </div>,
            <SimpleTable {...simpleTableOptions} noAutofetch paginator={this.paginator} columns={columns} />,
        ]
    }

    onMount() {
        this.applyFilters();
    }
}