import {UI, StyleSheet, styleRule, registerStyle} from "../../../stem-core/src/ui/UI";

export class InputContainerStyle extends StyleSheet {
    @styleRule
    default = {
        background: this.themeProps.INPUT_BACKGROUND_COLOR,
        fontSize: 14,
        padding: "0 10px",
        border: "1px solid " + this.themeProps.INACTIVE_BORDER_COLOR,
        borderRadius: this.themeProps.INPUT_BORDER_RADIUS,
        display: "flex",
        alignItems: "center",
        height: this.themeProps.INPUT_ELEMENT_DEFAULT_HEIGHT,
    }

    @styleRule
    separatedElements = {
        "> *": {
            padding: "8px 10px",
            cursor: "pointer",
            ":not(:last-child)": {
                borderRight: "1px solid " + this.themeProps.INACTIVE_BORDER_COLOR,
            }
        }
    }
}

// TODO - go through this
@registerStyle(InputContainerStyle)
export class InputContainer extends UI.Element {
    extraNodeAttributes(attr) {
        attr.addClass(this.styleSheet.default);
    }
}
