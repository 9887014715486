import {StyleSheet, styleRule, styleRuleCustom} from "../stem-core/src/ui/UI";
import {Device} from "stem-core/src/base/Device";

export class GlobalStyleSheet extends StyleSheet {
    @styleRule
    default = {
        height: "100%",
    };

    @styleRuleCustom({selector: "html"})
    html = {
        width: "100vw",
        fontSize: 10,
        overflow: () => (Device.isMobileDevice() ? "auto" : "overlay"),
        overflowX: "hidden",
        marginRight: () => (Device.isMobileDevice() ? 0 : "calc(-1 * (100vw - 100%))"),
        touchAction: "auto",
    };

    @styleRuleCustom({selector: "body"})
    body = {
        touchAction: "auto",
        background: this.themeProps.APP_BACKGROUND,
        margin: 0,
        fontSize: this.themeProps.FONT_SIZE_DEFAULT,
        fontFamily: this.themeProps.FONT_FAMILY_DEFAULT,
        ...(Device.isMobileDevice()
            ? {
                  cursor: "pointer",
                  "-webkit-tap-highlight-color": "transparent",
              }
            : {}),
        overflow: "initial !important",
    };

    @styleRuleCustom({selector: "input, select, textarea, button"})
    allInputs = {
        fontFamily: this.themeProps.FONT_FAMILY_DEFAULT,
    };

    @styleRuleCustom({selector: ".hidden"})
    hidden = {
        display: "none !important",
    };

    @styleRuleCustom({selector: "*"})
    everything = {
        boxSizing: "border-box",
    };

    @styleRuleCustom({selector: "a"})
    a = {
        textDecoration: "none",
        color: this.themeProps.COLOR_LINK,
    };

    @styleRuleCustom({selector: "hr"})
    hr = {
        height: 0,
        marginTop: "20px",
        marginBottom: "20px",
        border: 0,
        borderTop: "1px solid #eee",
        boxSizing: "content-box",
    };

    @styleRuleCustom({selector: "code, pre"})
    codeAndPre = {
        fontFamily: this.themeProps.FONT_FAMILY_MONOSPACE,
    };

    @styleRuleCustom({selector: "code"})
    code = {
        padding: "2px 4px",
        fontSize: "90%",
        color: "#345 !important", // TODO: take colors from theme
        backgroundColor: "#f8f2f4 !important",
        borderRadius: this.themeProps.BUTTON_BORDER_RADIUS,
    };

    @styleRuleCustom({selector: "pre"})
    pre = {
        overflow: "auto",
        display: "block",
        padding: this.themeProps.BUTTON_BORDER_RADIUS,
        margin: "0 0 10px",
        fontSize: "13px",
        lineHeight: "1.42857143",
        color: "#333",
        wordBreak: "break-all",
        wordWrap: "break-word",
        backgroundColor: "#f5f5f5",
        border: "1px solid #ccc",
    };
}
