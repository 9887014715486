import {UI} from "../../../../stem-core/src/ui/UIBase";
import {DashboardTitle} from "../../../../dashboard/common/DashboardTitle";
import {APIServerStats, TaskQueueStats, BackendDatabaseStats} from "./APIServerStats";

export class LandingPage extends UI.Element {
    render() {
        return [
            <DashboardTitle title="General statistics" />,
            <APIServerStats collapsed={true} />,
            <BackendDatabaseStats collapsed={true} />,
            <TaskQueueStats collapsed={true} />,
        ];
    }
}
