import {UI} from "stem-core/src/ui/UI";
import {ConfirmationModal} from "../../../../blinkpay/ui/ConfirmationModal";
import {CreateMerchantForm} from "./CreateMerchantForm";
import {LoadingSpinner} from "../../../../blinkpay/ui/LoadingSpinner";


export class CreateMerchantModal extends ConfirmationModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: "Create Merchant",
            confirmLabel: "Create Merchant",
            confirmAction: () => this.createMerchant(),
        }
    }

    async createMerchant() {
        const isFormValid = this.createMerchantForm && this.createMerchantForm.validateInputs();
        if (!isFormValid) {
            return;
        }

        await this.makeRequest("/admin/merchants/create_merchant", this.createMerchantForm.getInputValues());
    }

    render() {
        return [
            <CreateMerchantForm ref="createMerchantForm"/>,
            this.waitingForResponse ? <LoadingSpinner/> : null,
        ];
    }
}
