import {UI} from "../../../../../stem-core/src/ui/UIBase";
import {SubscriptionStore} from "../../../../../client/state/SubscriptionStore";
import {ArrayPaginator} from "../../../../../client/state/EndpointPaginator";
import {SimpleTable} from "../../../../../dashboard/ui/SimpleTable";
import {Card} from "../../ui/Card";

export class UserSubscriptionList extends UI.Element {
    render() {
        const {user} = this.options;
        const paginator = new ArrayPaginator(SubscriptionStore.filterBy({
            userId: user.id,
        }));

        const columns = [
            ["Id", sub => sub.id],
            ["Offer", sub => sub.offerId],
            ["Created at", sub =>sub.createdAt],
            ["Canceled at", sub => sub.canceledAt],
            ["Status", sub => sub.status],
        ];

        return (
            <Card title="Subscriptions">
                <SimpleTable columns={columns} paginator={paginator} />
            </Card>
        );
    }
}
